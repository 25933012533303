import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { Button, Container, Form, Label, Search } from 'semantic-ui-react'
import * as yup from 'yup'
import Customer from '../../models/customer'
import { useHistory } from 'react-router-dom'
import User from '../../models/user'
import _ from 'lodash'
import styles from '../pages.module.scss'

let schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email(),
  phone: yup.string().nullable(),
  address: yup.string().nullable(),
  state: yup.string().nullable(),
  city: yup.string().nullable(),
  county: yup.string().nullable(),
  country: yup.string().nullable(),
  zipcode: yup.string().nullable(),
  extension: yup.string().nullable(),
  contact: yup.object(),
  salesman: yup.object(),
})

export default function NewCustomer() {
  const history = useHistory()
  return (
    <Container>
      <h2>Create a new Customer</h2>

      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          address: '',
          city: '',
          state: '',
          county: '',
          country: '',
          zipcode: '',
          extension: '',
          contact: {} as any,
          salesman: {} as any,
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(async () => {
            let cus: any = new Customer({ ...values })

            if (values.contact?.id) {
              cus.contact = new User({ id: values.contact?.id })
              cus.contact.isPersisted = true
            } else {
              cus.contact = null
            }

            if (values.salesman?.id) {
              cus.salesman = new User({ id: values.salesman?.id })
              cus.salesman.isPersisted = true
            }
            const success = await cus.save({
              with: ['contact.id', 'salesman.id'],
            })
            if (success) {
              history.push(`/customers/${cus.id}`)
              setSubmitting(false)
            }
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }: /* and other goodies */
        any) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field
              required
              name="name"
              label="Name"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name && touched.name && errors.name}
              className={styles.newCustomerInputField}
            />
            <Form.Field
              type="email"
              name="email"
              label="Email"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email && errors.email}
              className={styles.newCustomerInputFieldSmall}
            />
            <Form.Field
              type="tel"
              name="phone"
              label="Phone"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              error={errors.phone && touched.phone && errors.phone}
              className={styles.newCustomerInputFieldSmall}
            />
            <Form.Field
              name="extension"
              label="Extension"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.extension}
              error={errors.extension && touched.extension && errors.extension}
              className={styles.newCustomerInputFieldSmall}
            />
            <Form.Field
              name="address"
              label="Address"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              error={errors.address && touched.address && errors.address}
              className={styles.newCustomerInputField}
            />
            <Form.Field
              name="city"
              label="City"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              error={errors.city && touched.city && errors.city}
              className={styles.newCustomerInputFieldSmall}
            />
            <Form.Field
              name="state"
              label="State"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              error={errors.state && touched.state && errors.state}
              className={styles.newCustomerInputFieldSmall}
            />
            <Form.Field
              name="county"
              label="County"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.county}
              error={errors.county && touched.county && errors.county}
              className={styles.newCustomerInputFieldSmall}
            />
            <Form.Field
              name="country"
              label="Country"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              error={errors.country && touched.country && errors.country}
              className={styles.newCustomerInputFieldHalf}
            />
            <Form.Field
              name="zipcode"
              label="Zipcode"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zipcode}
              error={errors.zipcode && touched.zipcode && errors.zipcode}
              className={styles.newCustomerInputFieldHalf}
            />
            <Form.Field className={styles.newCustomerInputFieldHalf}>
              <label>Contact: {values.contact?.name}</label>
              <UserSearch
                onChange={setFieldValue}
                fieldName="contact"
                value={values.contact?.name}
              />
            </Form.Field>
            <Form.Field required className={styles.newCustomerInputFieldHalf}>
              <label>Salesman: {values.salesman?.name}</label>
              <UserSearch
                onChange={setFieldValue}
                fieldName="salesman"
                value={values.salesman?.name}
                userType="employee"
              />
            </Form.Field>

            <div className={styles.newCustomerSubmitBtn}>
              <Button primary type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

function UserSearch(props: any) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    if (!searchTerm) return
    let scope: any = User
    if (props.userType) {
      scope = scope.where({
        user_type: props.userType,
      })
    }
    let { data: userData }: any = await scope
      .where({
        status: 'active',
        name: { fuzzyMatch: searchTerm.replace('&', '') },
      })
      .all()
    setResults(userData)
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    if (props.value) onChange(props.fieldName, {})
    setSearchTerm(data.value || null)
  }, 600)

  function handleSelectItem(e: any, data: any) {
    onChange(props.fieldName, data.result)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a user name"
    />
  )
}
