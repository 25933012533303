import _ from 'lodash'
import { useState, useEffect } from 'react'
import { Button, Header, Label, Modal, Search } from 'semantic-ui-react'
import Manufacturer from '../../models/manufacturer'
import ManufacturerModal from '../../pages/manufacturers/manufacturerModal'
import styles from '../components.module.scss'

interface ManufacturerSearchProps {
  value: any
  triggerNew?: () => void | undefined
  onChange: (key: string, value: object) => any
}

export function ManufacturerSearch(props: ManufacturerSearchProps) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const [manufacturerModalOpen, setManuModal] = useState<any>(false)

  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    let { data: manufacturerData }: any = await Manufacturer.where({
      status: 'active',
      name: { fuzzyMatch: searchTerm.replace('&', '') },
    }).all()
    setResults(manufacturerData)
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    if (props.value) onChange('manufacturer', {})
    setSearchTerm(data.value)
  }, 600)

  function handleSelectItem(e: any, data: any) {
    onChange('manufacturer', data.result)
    setSearchTerm('')
  }

  return (
    <div className={styles.vendorSearch}>
      <Search
        loading={loading}
        onResultSelect={handleSelectItem}
        onSearchChange={handleSearchChange}
        noResultsMessage={
          <p onClick={() => setManuModal(true)}>Add New Manufacturer</p>
        }
        resultRenderer={resultRenderer}
        results={results}
        value={props.value}
        style={{ flex: 1 }}
        placeholder="Enter a manufacturer name"
      />
      <Button
        className={styles.vendorButton}
        onClick={() => setManuModal(true)}
      >
        Add New Manufacturer
      </Button>

      <Modal onClose={() => setManuModal(false)} open={manufacturerModalOpen}>
        <Modal.Content>
          <Modal.Description>
            <Header>Add New Manufacturer</Header>
            <ManufacturerModal
              handleCloseModal={(man: any) => {
                if (man) handleSelectItem(null, { result: man })
                setManuModal(false)
              }}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  )
}
