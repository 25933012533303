import { Attr, HasMany, HasOne, Model } from 'spraypaint'
import ApplicationRecord from './applicationRecord'
import Job from './job'
import Note from './note'
import PurchaseOrder from './purchase_order'

@Model()
class Customer extends ApplicationRecord {
  static jsonapiType = 'customers'
  @Attr() name: string
  @Attr() email: string
  @Attr() phone: string
  @Attr() address: string
  @Attr() city: string
  @Attr() state: string
  @Attr() zipcode: string
  @Attr() county: string
  @Attr() country: string
  @Attr() extension: string

  @Attr() status: string

  @HasMany() notes: Note[]
  @HasOne() contact: Contact
  @HasOne() salesman: Salesman
  @HasMany() purchaseOrders: PurchaseOrder[]
  @HasMany() jobs: Job[]
}

@Model()
export class Contact extends ApplicationRecord {
  static jsonapiType = 'contacts'
  @Attr() name: string
  @Attr() email: string
  @Attr() phone: string
  @Attr() title: string
  @Attr() userType: string

  @Attr() status: string
}

@Model()
export class Salesman extends ApplicationRecord {
  static jsonapiType = 'salesman'
  @Attr() name: string
  @Attr() email: string
  @Attr() phone: string
  @Attr() title: string
  @Attr() userType: string

  @Attr() status: string
}

export default Customer
