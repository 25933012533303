import Job from 'models/job'
import { useEffect, useState } from 'react'
import { useWebsocketConnection } from 'shared/hooks/useWebsocketConnection'

interface Props {
  handleUpdatedJobsPayload: (job: Job) => void
  handleRemovedJobPayload: (id: number) => void
}

export type JobsChannelRecievedPayload = {
  job: { data: Job }
  action: 'add' | 'update' | 'remove'
}

export const useJobsFeedChannel = ({
  handleUpdatedJobsPayload,
  handleRemovedJobPayload,
}: Props) => {
  const [websocketSubscription, setWebsocketSubscription] = useState<any>()
  const websocketConnection = useWebsocketConnection()

  useEffect(() => {
    if (websocketSubscription) return

    const subscription = websocketConnection?.subscriptions?.create(
      { channel: 'JobsFeedChannel' },
      {
        received: ({ job, action }: JobsChannelRecievedPayload) => {
          const { data } = job
          if (action === 'remove') {
            if (data.id) handleRemovedJobPayload(parseInt(data.id))
          } else {
            const newJob = new Job(data)
            handleUpdatedJobsPayload(newJob)
          }
        },
      }
    )

    setWebsocketSubscription(subscription)

    return () => {
      if (subscription) {
        subscription.unsubscribe()
        setWebsocketSubscription(null)
        websocketConnection.disconnect()
      }
    }
  }, [websocketConnection])

  return websocketSubscription
}
