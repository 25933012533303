import { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router'
import {
  Container,
  Dimmer,
  Loader,
  Table,
  Placeholder,
  Pagination,
  Button,
  Icon,
  Input,
} from 'semantic-ui-react'

import Vendor from '../../models/vendor'
import { useHistory, useLocation } from 'react-router-dom'
import QueryString from 'qs'
import EditVendor from './editVendor'
import NewVendor from './newVendor'
import ShowVendor from './showVendor'
import _ from 'lodash'
import styles from '../pages.module.scss'
import useMobile from '../../shared/hooks/useMobile'

export default function VendorRoutes() {
  return (
    <Switch>
      <Route exact path="/vendors">
        <VendorListView />
      </Route>
      <Route exact path="/vendors/new">
        <NewVendor />
      </Route>
      <Route path="/vendors/:id/edit">
        <EditVendor />
      </Route>
      <Route path="/vendors/:id">
        <ShowVendor />
      </Route>
    </Switch>
  )
}

function VendorListView() {
  const param: any = useParams()

  const history: any = useHistory()
  const location: any = useLocation()
  const isMobile = useMobile()
  let search: any = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const [loading, setLoading] = useState<any>(false)
  const [vendors, setVendors] = useState<any>([])
  const [totalPages, setTotalPages] = useState<any>(0)

  useEffect(() => {
    getVendors()
  }, [param])

  useEffect(() => {
    document.title = 'Vendors | PFP'
  }, [])

  async function getVendors() {
    setLoading(true)

    let scope: any = Vendor
    scope = scope
      .where({ status: 'active' })
      .page(search?.page)
      .per(10)
      .order({ name: 'asc' })
    if (search?.localSearchTerm) {
      scope = scope.where({ name: { fuzzyMatch: search?.localSearchTerm } })
    } else {
      scope = scope.stats({ total: 'count' })
    }

    let data = await scope.all()

    if (data.meta.stats) {
      setTotalPages(Math.ceil(data.meta.stats.total.count / 10))
    } else {
      setTotalPages(Math.ceil(data.data.length / 10))
    }

    setVendors(data.data)
    setLoading(false)
  }

  async function handlePageChange(e: any, data: any) {
    search.page = data.activePage
    history.push(location.pathname + `?${QueryString.stringify(search)}`)
  }

  if (loading) {
    return (
      <Container>
        <Dimmer active>
          <Loader />
        </Dimmer>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Container>
    )
  }

  function handleEditClick(id: any) {
    history.push(location.pathname + `/${id}/edit`)
  }

  function handleShowClick(id: any) {
    history.push(location.pathname + `/${id}`)
  }
  function handleNewClick(id: any) {
    history.push(location.pathname + `/new`)
  }

  async function handleDelete(vendor: any) {
    const r = window.confirm(`Are you sure you want to delete: ${vendor.name}`)
    if (r) {
      const { data: ven } = await Vendor.find(vendor.id)
      ven.isPersisted = true
      ven.status = 'removed'
      await ven.save()
      getVendors()
    }
  }

  const handleSearch = _.debounce(async (e: any) => {
    let query: any = {}
    if (search?.page) {
      query.page = search?.page
    }
    query.localSearchTerm = e.target?.value
    history.push(location.pathname + `?${QueryString.stringify(query)}`)
  }, 600)

  return (
    <Container>
      <Input
        onChange={handleSearch}
        defaultValue={search?.localSearchTerm}
        fluid
        icon="search"
        iconPosition="left"
        loading={loading}
        placeholder="Search by vendor name, email, or phone number"
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>
              <Button
                positive
                icon
                fluid
                labelPosition="left"
                onClick={handleNewClick}
              >
                <Icon name="plus" />
                New Vendor
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {vendors.map((customer: any) => {
            return (
              <Table.Row key={customer.id}>
                <Table.Cell>{customer.id}</Table.Cell>
                <Table.Cell>{customer.name}</Table.Cell>
                <Table.Cell>{customer.address}</Table.Cell>
                <Table.Cell>{customer.phone}</Table.Cell>
                <Table.Cell>{customer.status}</Table.Cell>
                <Table.Cell>
                  <Button
                    icon
                    fluid
                    labelPosition="left"
                    className={styles.routesButton}
                    onClick={() => handleEditClick(customer.id)}
                  >
                    <Icon name="edit" />
                    Edit
                  </Button>
                  <Button
                    icon
                    fluid
                    labelPosition="left"
                    className={styles.routesButton}
                    onClick={() => handleShowClick(customer.id)}
                  >
                    <Icon name="book" />
                    View
                  </Button>
                  {customer.status !== 'removed' && (
                    <Button
                      icon
                      fluid
                      negative
                      labelPosition="left"
                      onClick={() => handleDelete(customer)}
                    >
                      <Icon name="delete" />
                      Delete
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {totalPages > 0 ? (
        <Pagination
          size={isMobile ? 'mini' : ''}
          ellipsisItem={false}
          defaultActivePage={search.page || 1}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          siblingRange={isMobile ? -1 : 1}
        />
      ) : (
        <></>
      )}
    </Container>
  )
}
