import { createConsumer } from '@rails/actioncable'
import { useEffect, useState } from 'react'
import { tokenWithPrefix } from 'shared/hooks/userHook'
import url from 'utils/base_url'

export const useWebsocketConnection = () => {
  const userToken = localStorage.getItem(tokenWithPrefix)
  const query = new URLSearchParams({ jwt: userToken || '' })
  const connectionUrl = `${url}/cable?${query}`
  // Make this global state
  const [websocketConnection, setWebsocketConnection] = useState<any>()

  useEffect(() => {
    const newConnection = createConsumer(connectionUrl)
    setWebsocketConnection(newConnection)
  }, [connectionUrl])

  return websocketConnection
}
