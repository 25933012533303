import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import {
  Button,
  Container,
  Form,
  Label,
  Message,
  Search,
} from 'semantic-ui-react'
import * as yup from 'yup'
import Customer from '../../models/customer'
import { useHistory } from 'react-router-dom'
import PurchaseOrder from '../../models/purchase_order'
import _ from 'lodash'
import Vendor from '../../models/vendor'
import User from '../../models/user'
import Note from '../../models/note'
import { shippingMethod } from '../../shared/utils/shippingMethodUtil'
import { shippingSpeed } from '../../shared/utils/shippingSpeedUtil'
import { ManufacturerSearch } from '../../components/ManufacturerSearch/manufacturerSearch'
import { VendorSearch } from '../../components/VendorSearch/vendorSearch'

let schema = yup.object().shape({
  shippingMethod: yup.string().required(),
  shippingSpeed: yup.string().required(),
  customer: yup.string().nullable(),
  user: yup.object().nullable(),
  vendor: yup.object().nullable(),
  manufacturer: yup.object().nullable(),
})

const shippingMethodOptions = Object.keys(shippingMethod).map((item: any) => {
  return {
    text: shippingMethod[item],
    value: item.toLowerCase(),
    key: item.toLowerCase(),
  }
})

const shippingSpeedOptions = Object.keys(shippingSpeed).map((item: any) => {
  return {
    text: shippingSpeed[item],
    value: item.toLowerCase(),
    key: item.toLowerCase(),
  }
})

export default function PurchaseOrderByCustomer() {
  const params: any = useParams()
  const history = useHistory()
  const [success, setSuccess] = useState<any>(null)
  const [customer, setCustomer] = useState<any>(null)

  useEffect(() => {
    fetchCustomer()
  }, [])

  async function fetchCustomer() {
    let { data } = await Customer.includes('salesman').find(params.id)
    setCustomer(data)
  }

  function handleSuccessToast(po: any) {
    setSuccess(po.id)
    setTimeout(() => {
      setSuccess(null)
    }, 6000)
  }

  function goToPurchaseOrder(po: any) {
    history.push(`/purchase-orders/${po}`)
  }

  if (!customer) return <></>

  return (
    <Container>
      <h2>Create a new Purchase Order for: {customer.name}</h2>
      {success && (
        <Message
          success
          header="Success!"
          content={
            <Button primary onClick={() => goToPurchaseOrder(success)}>
              Your purchase order number is: {success}
            </Button>
          }
        />
      )}
      <Formik
        initialValues={{
          vendor: {},
          manufacturer: {},
          user: customer.salesman || {},
          note: '',
          shippingMethod: '',
          shippingSpeed: '',
        }}
        validationSchema={schema}
        onSubmit={(values: any, { setSubmitting, resetForm }) => {
          setTimeout(async () => {
            let po: any = new PurchaseOrder()
            po.customer = new Customer({ id: customer.id })
            po.customer.isPersisted = true

            if (values.vendor?.id) {
              po.vendor = new Vendor({ id: values.vendor?.id })
              po.vendor.isPersisted = true
            }
            if (values.manufacturer?.id) {
              po.manufacturer = new Vendor({ id: values.manufacturer?.id })
              po.manufacturer.isPersisted = true
            }
            if (values.user?.id) {
              po.user = new User({ id: values.user?.id })
              po.user.isPersisted = true
            }

            if (values.note) {
              let note = new Note()
              note.text = values.note
              po.notes.push(note)
            }

            po.shippingMethod = values.shippingMethod
            po.shippingSpeed = values.shippingSpeed

            const success = await po.save({
              with: [
                'vendor.id',
                'user.id',
                'notes',
                'customer.id',
                'manufacturer.id',
              ],
            })
            if (success) {
              setSubmitting(false)
              resetForm({})
              handleSuccessToast(po)
            }
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }: /* and other goodies */
        any) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Vendor: {values.vendor?.name}</label>
              <VendorSearch
                onChange={setFieldValue}
                value={values.vendor?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Manufacturer: {values.manufacturer?.name}</label>
              <ManufacturerSearch
                onChange={setFieldValue}
                value={values.manufacturer?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Salesman: {values.user?.name}</label>
              <UserSearch onChange={setFieldValue} value={values.user?.name} />
            </Form.Field>

            <Form.Field
              name="note"
              label="Note:"
              control="TextArea"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.note}
              error={errors.note && touched.note && errors.note}
            />
            <Form.Select
              label="Shipping Method"
              name="shippingMethod"
              placeholder="Select Shipping Method"
              options={shippingMethodOptions}
              onChange={(e: any, data: any) =>
                setFieldValue('shippingMethod', data.value)
              }
              error={
                errors.shippingMethod &&
                touched.shippingMethod &&
                errors.shippingMethod
              }
            />

            <Form.Select
              label="Shipping Speed"
              name="shippingSpeed"
              placeholder="Select Shipping Speed"
              options={shippingSpeedOptions}
              onChange={(e: any, data: any) =>
                setFieldValue('shippingSpeed', data.value)
              }
              error={
                errors.shippingSpeed &&
                touched.shippingSpeed &&
                errors.shippingSpeed
              }
            />

            <Button primary type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

function UserSearch(props: any) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    if (!searchTerm) return
    let { data: userData }: any = await User.where({
      status: 'active',
      user_type: 'employee',
      name: { fuzzyMatch: searchTerm.replace('&', '') },
    }).all()
    setResults(userData)
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    if (props.value) onChange('user', {})
    setSearchTerm(data.value)
  }, 600)

  function handleSelectItem(e: any, data: any) {
    onChange('user', data.result || null)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a user name"
    />
  )
}
