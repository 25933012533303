import { Formik } from 'formik'
import React, { useState, useEffect } from 'react'
import {
  Button,
  Container,
  Form,
  Label,
  Message,
  Search,
  SearchResultProps,
} from 'semantic-ui-react'
import * as yup from 'yup'
import Customer from '../../models/customer'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import Vendor from '../../models/vendor'
import User from '../../models/user'
import Note from '../../models/note'

import Job from '../../models/job'
import { ManufacturerSearch } from '../../components/ManufacturerSearch/manufacturerSearch'
import { VendorSearch } from '../../components/VendorSearch/vendorSearch'
import { PersistedSpraypaintRecord } from 'spraypaint'
import { jobTypeOptions } from 'shared/utils/jobTypeUtil'

let schema = yup.object().shape({
  jobType: yup.string().required(),
  customer: yup.object().required(),
  user: yup.object().nullable(),
  vendor: yup.object().nullable(),
  manufacturer: yup.object().nullable(),
})

interface NewJobProps {
  customer?: string | { id: number }
  user?: string
  vendor?: string
  manufacturer?: string
  salesman?: string
  note?: string
  id?: number
}

interface OnSubmitValues {
  jobType: string
  customer?: { id: string }
  vendor?: { id: string }
  manufacturer?: { id: string }
  user?: { id: string }
  note?: string
}

const initialValues: any = {
  jobType: '',
  vendor: {},
  manufacturer: {},
  user: {},
  note: '',
}

function JobNew(props: NewJobProps) {
  const history: any = useHistory()
  const [success, setSuccess] = useState<string | number>()

  function handleSuccessToast(po: Job) {
    setSuccess(po.id)
    setTimeout(() => {
      setSuccess('')
    }, 6000)
  }

  function goToJob(id: number | string) {
    history.push(`/jobs/${id}`)
  }

  return (
    <Container>
      {success && (
        <Message
          success
          header="Success!"
          content={
            <Button primary onClick={() => goToJob(success)}>
              Your Job Number is: {success}
            </Button>
          }
        />
      )}
      <h2>Create a new Job</h2>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values: OnSubmitValues, { setSubmitting, resetForm }) => {
          setTimeout(async () => {
            let job: Job = new Job()
            job.customer = new Customer({ id: values.customer?.id })
            job.customer.isPersisted = true

            if (values.vendor?.id) {
              job.vendor = new Vendor({ id: values.vendor?.id })
              job.vendor.isPersisted = true
            }
            if (values.manufacturer?.id) {
              job.manufacturer = new Vendor({ id: values.manufacturer?.id })
              job.manufacturer.isPersisted = true
            }
            if (values.user?.id) {
              job.user = new User({ id: values.user?.id })
              job.user.isPersisted = true
            }

            if (values.note) {
              let note = new Note()
              note.text = values.note
              job.notes.push(note)
            }

            job.jobType = values.jobType

            const success = await job.save({
              with: [
                'vendor.id',
                'user.id',
                'notes',
                'customer.id',
                'manufacturer.id',
              ],
            })
            if (success) {
              setSubmitting(false)
              resetForm(initialValues)
              handleSuccessToast(job)
            }
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }: /* and other goodies */
        any) => (
          <Form onSubmit={handleSubmit}>
            <Form.Select
              required
              label="Job Type"
              name="jobType"
              placeholder="Select Job Type"
              options={jobTypeOptions}
              value={values.jobType}
              onChange={(e: any, data: any) =>
                setFieldValue('jobType', data.value)
              }
              error={errors.jobType && touched.jobType && errors.jobType}
            />

            <Form.Field required>
              <label>Customer: {values.customer?.name}</label>
              <CustomerSearch
                onChange={setFieldValue}
                value={values.customer?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Vendor: {values.vendor?.name}</label>
              <VendorSearch
                onChange={setFieldValue}
                value={values.vendor?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Manufacturer: {values.manufacturer?.name}</label>
              <ManufacturerSearch
                onChange={setFieldValue}
                value={values.manufacturer?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Salesman: {values.user?.name}</label>
              <UserSearch onChange={setFieldValue} value={values.user?.name} />
            </Form.Field>

            <Form.Field
              name="note"
              label="Note:"
              control="TextArea"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.note}
              error={errors.note && touched.note && errors.note}
            />

            <Button primary type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

interface SearchProps {
  value: string
  onChange: (value: string, value2: object) => void
}

interface HandleSelectItem {
  result: { salesman: object }
}

function UserSearch(props: SearchProps) {
  const [loading] = useState(false)
  const [results, setResults] = useState<PersistedSpraypaintRecord<User>[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    if (!searchTerm) return
    let { data: userData } = await User.where({
      status: 'active',
      user_type: 'employee',
      name: { fuzzyMatch: searchTerm.replace('&', '') },
    }).all()
    setResults(userData)
  }
  const handleSearchChange = _.debounce(async (e, data) => {
    if (props.value) onChange('user', {})
    setSearchTerm(data.value || null)
  }, 600)

  function handleSelectItem(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: HandleSelectItem
  ) {
    onChange('user', data.result)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a user name"
    />
  )
}

function CustomerSearch(props: SearchProps) {
  const [loading] = useState(false)
  const [results, setResults] = useState<PersistedSpraypaintRecord<Customer>[]>(
    []
  )
  const [searchTerm, setSearchTerm] = useState('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: SearchResultProps) => (
    <Label content={name} />
  )

  async function getResults() {
    if (!searchTerm) return
    let { data: custData } = await Customer.includes('salesman')
      .where({
        name: { fuzzyMatch: searchTerm.replace('&', '') },
      })
      .all()
    setResults(custData)
  }
  const handleSearchChange = _.debounce(async (e, data) => {
    if (props.value) onChange('customer', {})
    setSearchTerm(data.value || null)
  }, 600)

  function handleSelectItem(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: HandleSelectItem
  ) {
    onChange('customer', data.result)
    onChange('user', data.result.salesman)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a customer name"
    />
  )
}

export default JobNew
