import ApplicationRecord from './applicationRecord'
import { Attr, Model } from 'spraypaint'

@Model()
class Credential extends ApplicationRecord {
  static jsonapiType = 'credentials'
  @Attr() email: string
  @Attr() password: string
  @Attr() jsonWebToken: string
}

export default Credential
