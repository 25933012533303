import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Button, Container, Form, Label, Search } from 'semantic-ui-react'
import * as yup from 'yup'
import Customer from '../../models/customer'
import { useHistory } from 'react-router-dom'
import PurchaseOrder from '../../models/purchase_order'
import _ from 'lodash'
import Vendor from '../../models/vendor'
import User from '../../models/user'
import Note from '../../models/note'
import Manufacturer from '../../models/manufacturer'
import Job from '../../models/job'
import { jobTypeOptions } from 'shared/utils/jobTypeUtil'

let schema = yup.object().shape({
  jobType: yup.string().required(),
  customer: yup.string().nullable(),
  purchaseOrder: yup.object().nullable(),
  user: yup.object().nullable(),
  vendor: yup.object().nullable(),
  manufacturer: yup.object().nullable(),
})

function JobForCustomer(props: any) {
  const params: any = useParams()
  const history = useHistory()
  const [customer, setCustomer] = useState<any>(null)

  useEffect(() => {
    fetchCustomer()
  }, [])

  async function fetchCustomer() {
    let { data } = await Customer.includes('salesman').find(params.id)
    setCustomer(data)
  }

  if (!customer) return <></>

  return (
    <Container>
      <h2>
        Create a new Job for: {customer.name} {customer.email}
      </h2>
      <Formik
        initialValues={{
          jobType: 'unassigned',
          vendor: {},
          manufacturer: {},
          purchaseOrder: {},
          user: customer.salesman || {},
          note: '',
          shippingMethod: '',
          shippingSpeed: '',
        }}
        validationSchema={schema}
        onSubmit={(values: any, { setSubmitting }) => {
          setTimeout(async () => {
            let job: any = new Job()
            job.customer = new Customer({ id: customer.id })
            job.customer.isPersisted = true
            if (values.purchaseOrder.id) {
              job.purchaseOrder = new PurchaseOrder({
                id: values.purchaseOrder.id,
              })
              job.purchaseOrder.isPersisted = true
            }

            if (values.vendor?.id) {
              job.vendor = new Vendor({ id: values.vendor?.id })
              job.vendor.isPersisted = true
            }
            if (values.manufacturer?.id) {
              job.manufacturer = new Vendor({ id: values.manufacturer?.id })
              job.manufacturer.isPersisted = true
            }
            if (values.user?.id) {
              job.user = new User({ id: values.user?.id })
              job.user.isPersisted = true
            }

            if (values.note) {
              let note = new Note()
              note.text = values.note
              job.notes.push(note)
            }

            job.jobType = values.jobType

            const success = await job.save({
              with: [
                'vendor.id',
                'user.id',
                'notes',
                'purchaseOrder.id',
                'customer.id',
                'manufacturer.id',
              ],
            })
            if (success) {
              history.push(`/jobs/${job.id}`)
              setSubmitting(false)
            }
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }: /* and other goodies */
        any) => (
          <Form onSubmit={handleSubmit}>
            <Form.Select
              label="Job Type"
              name="jobType"
              placeholder="Select Job Type"
              options={jobTypeOptions}
              value={values.jobType}
              onChange={(e: any, data: any) =>
                setFieldValue('jobType', data.value)
              }
              error={errors.jobType && touched.jobType && errors.jobType}
            />

            <Form.Field>
              <label>Purchase Order: {values.purchaseOrder?.id}</label>
              <PurchaseOrderSearch
                customer={customer}
                onChange={setFieldValue}
                value={values.purchaseOrder?.id}
              />
            </Form.Field>
            <Form.Field>
              <label>Vendor: {values.vendor?.name}</label>
              <VendorSearch
                onChange={setFieldValue}
                value={values.vendor?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Manufacturer: {values.manufacturer?.name}</label>
              <ManufacturerSearch
                onChange={setFieldValue}
                value={values.manufacturer?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Salesman: {values.user?.name}</label>
              <UserSearch onChange={setFieldValue} value={values.user?.name} />
            </Form.Field>

            <Form.Field
              name="note"
              label="Note:"
              control="TextArea"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.note}
              error={errors.note && touched.note && errors.note}
            />

            <Button primary type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

function VendorSearch(props: any) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    if (!searchTerm) return
    let { data: vendorData }: any = await Vendor.where({
      status: 'active',
      name: { fuzzyMatch: searchTerm.replace('&', '') },
    }).all()
    setResults(vendorData)
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    if (props.value) onChange('vendor', {})
    setSearchTerm(data.value || null)
  }, 600)

  function handleSelectItem(e: any, data: any) {
    onChange('vendor', data.result)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a vendor name"
    />
  )
}

function UserSearch(props: any) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    if (!searchTerm) return
    let { data: userData }: any = await User.where({
      name: { fuzzyMatch: searchTerm.replace('&', '') },
    }).all()
    setResults(userData)
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    if (props.value) onChange('user', {})
    setSearchTerm(data.value || null)
  }, 600)

  function handleSelectItem(e: any, data: any) {
    onChange('user', data.result)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a user name"
    />
  )
}

function ManufacturerSearch(props: any) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    if (!searchTerm) return
    let { data: manufacturerData }: any = await Manufacturer.where({
      name: { fuzzyMatch: searchTerm.replace('&', '') },
    }).all()
    setResults(manufacturerData)
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    if (props.value) onChange('manufacturer', {})
    setSearchTerm(data.value || null)
  }, 600)

  function handleSelectItem(e: any, data: any) {
    onChange('manufacturer', data.result)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a manufacturer name"
    />
  )
}

function PurchaseOrderSearch(props: any) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ specialId }: any) => (
    <Label content={`${specialId}`} />
  )

  async function getResults() {
    try {
      let { data: poData }: any = await PurchaseOrder.where({
        customer: props.customer,
      }).all()

      let finalRes = poData
        .filter((x: any) => x.id === searchTerm)
        .map((po: any) => {
          return {
            ...po,
            specialId: po.id,
          }
        })

      setResults(finalRes)
    } catch (err) {
      setResults([])
    }
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    if (props.value) onChange('purchaseOrder', {})
    setSearchTerm(data.value)
  }, 600)

  function handleSelectItem(e: any, data: any) {
    onChange('purchaseOrder', data.result)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a purchase order ID"
    />
  )
}

export default JobForCustomer
