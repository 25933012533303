import { useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import LeftNav from '../components/left-nav/left-nav'
import ApplicationRecord from '../models/applicationRecord'
import CustomerRoutes from '../pages/customers/customerRoutes'
import JobRoutes from '../pages/jobs/jobRoutes'
import ManufacturerRoutes from '../pages/manufacturers/manufacturerRoutes'
import PurchaseOrderRoutes from '../pages/purchase_orders/purchaseOrderRoutes'
import UserRoutes from '../pages/users/userRoutes'
import VendorRoutes from '../pages/vendors/vendorRoutes'
import DashboardRoutes from '../pages/dashboard/dashboard'
import CurrentUser from '../shared/hooks/userHook'
import { Container, Dimmer, Loader } from 'semantic-ui-react'

function LogoutRoute() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    logout()
  }, [])

  async function logout() {
    await localStorage.clear()
    ApplicationRecord.jwt = undefined
    setLoading(false)
  }

  if (loading) {
    return (
      <Container>
        <Dimmer active>
          <Loader />
        </Dimmer>
      </Container>
    )
  }
  return <Redirect to="/login" />
}

export default function MainLayout() {
  const user = CurrentUser()
  if (!user) return null
  return (
    <>
      <LeftNav />
      <div style={{ display: 'flex', flex: 1, padding: '1rem' }}>
        {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/dashboard">
            <DashboardRoutes />
          </Route>
          <Route path="/purchase-orders">
            <PurchaseOrderRoutes />
          </Route>
          <Route path="/jobs">
            <JobRoutes />
          </Route>
          <Route path="/customers">
            <CustomerRoutes />
          </Route>
          <Route path="/vendors">
            <VendorRoutes />
          </Route>
          <Route path="/manufacturers">
            <ManufacturerRoutes />
          </Route>
          <Route path="/users">
            <UserRoutes />
          </Route>
          <Route path="/logout">
            <LogoutRoute />
          </Route>
          <Route path="/*">
            <Redirect to="/customers" />
          </Route>
        </Switch>
      </div>
    </>
  )
}
