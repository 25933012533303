import url from '../../utils/base_url'

export async function getAttachmentsForJob(job_id: any) {
  return fetch(`${url}/attachments/${job_id}`).then((res) => res.json())
}

export default async function addAttachments(formData: any, job_id: any) {
  return fetch(`${url}/attachments/${job_id}`, {
    method: 'PATCH',
    body: formData,
  }).then((res) => res.json())
}
