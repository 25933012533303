import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Button, Container, Form, Label, Search } from 'semantic-ui-react'
import * as yup from 'yup'
import Customer from '../../models/customer'
import { useHistory } from 'react-router-dom'
import PurchaseOrder from '../../models/purchase_order'
import _ from 'lodash'
import Vendor from '../../models/vendor'
import User from '../../models/user'
import Note from '../../models/note'
import Job from '../../models/job'
import { ManufacturerSearch } from '../../components/ManufacturerSearch/manufacturerSearch'
import { VendorSearch } from '../../components/VendorSearch/vendorSearch'

let schema = yup.object().shape({
  customer: yup.string().nullable(),
  user: yup.object().nullable(),
  vendor: yup.object().nullable(),
  manufacturer: yup.object().nullable(),
})

function JobForPurchaseOrder(props: any) {
  const params: any = useParams()
  const history = useHistory()
  const [po, setPo] = useState<any>(null)
  useEffect(() => {
    fetchCustomer()
  }, [])

  async function fetchCustomer() {
    let { data } = await PurchaseOrder.includes('customer').find(params.id)
    setPo(data)
  }

  if (!po) return <></>

  return (
    <Container>
      <h2>Create a new Job for: {po.id}</h2>
      <Formik
        initialValues={{
          vendor: {},
          manufacturer: {},
          user: po.user || {},
          note: '',
          shippingMethod: '',
          shippingSpeed: '',
        }}
        validationSchema={schema}
        onSubmit={(values: any, { setSubmitting }) => {
          setTimeout(async () => {
            let job: any = new Job()
            job.customer = new Customer({ id: po.customer.id })
            job.customer.isPersisted = true
            job.purchaseOrder = new PurchaseOrder({ id: po.id })
            job.purchaseOrder.isPersisted = true

            if (values.vendor?.id) {
              job.vendor = new Vendor({ id: values.vendor?.id })
              job.vendor.isPersisted = true
            }
            if (values.manufacturer?.id) {
              job.manufacturer = new Vendor({ id: values.manufacturer?.id })
              job.manufacturer.isPersisted = true
            }
            if (values.user?.id) {
              job.user = new User({ id: values.user?.id })
              job.user.isPersisted = true
            }

            if (values.note) {
              let note = new Note()
              note.text = values.note
              job.notes.push(note)
            }

            const success = await job.save({
              with: [
                'vendor.id',
                'user.id',
                'notes',
                'purchaseOrder.id',
                'customer.id',
                'manufacturer.id',
              ],
            })
            if (success) {
              history.push(`/jobs/${job.id}`)
              setSubmitting(false)
            }
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }: /* and other goodies */
        any) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field>
              <label>Vendor: {values.vendor?.name}</label>
              <VendorSearch
                onChange={setFieldValue}
                value={values.vendor?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Manufacturer: {values.manufacturer?.name}</label>
              <ManufacturerSearch
                onChange={setFieldValue}
                value={values.manufacturer?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Salesman: {values.user?.name}</label>
              <UserSearch onChange={setFieldValue} value={values.user?.name} />
            </Form.Field>

            <Form.Field
              name="note"
              label="Note:"
              control="TextArea"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.note}
              error={errors.note && touched.note && errors.note}
            />

            <Button primary type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

function UserSearch(props: any) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    let { data: userData }: any = await User.where({
      status: 'active',
      user_type: 'employee',
      name: { fuzzyMatch: searchTerm.replace('&', '') },
    }).all()
    setResults(userData)
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    if (props.value) onChange('user', {})
    setSearchTerm(data.value)
  }, 600)

  function handleSelectItem(e: any, data: any) {
    onChange('user', data.result)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a user name"
    />
  )
}

export default JobForPurchaseOrder
