import { useParams } from 'react-router'
import { useEffect, useMemo, useState } from 'react'
import {
  Button,
  Card,
  Container,
  Form,
  Grid,
  Icon,
  Modal,
  Tab,
  Table,
  TextArea,
  Image,
  Input,
  Dropdown,
} from 'semantic-ui-react'
import { useHistory, useLocation } from 'react-router-dom'
import { PDFDocument } from 'pdf-lib'

import Note from '../../models/note'
import addAttachments, {
  getAttachmentsForJob,
} from '../../shared/utils/attachments'
import Job from '../../models/job'
import moment from 'moment'
import url from '../../utils/base_url'
import { jobToPdf } from '../../shared/utils/jobToPdf'
import styles from '../pages.module.scss'
import { jobTypes } from 'shared/utils/jobTypeUtil'

export default function JobsShow() {
  const param: any = useParams()
  const history: any = useHistory()
  const location: any = useLocation()
  const [text, setTextForModal] = useState('')
  const [addPhotoModal, setAddPhotoModal] = useState(false)
  const [inboundPhoto, setInboundPhoto] = useState<any>(null)
  const [addNoteModal, setAddNoteModal] = useState(false)

  const [job, setJob] = useState<any>(null)
  useEffect(() => {
    getJob()
  }, [param])

  async function getJob() {
    if (!param) return
    let { data }: any = await Job.includes([
      'notes',
      'customer',
      'user',
      'vendor',
      'manufacturer',
      'purchase_order',
      'purchase_order.customer',
      'purchase_order.vendor',
      'purchase_order.manufacturer',
      'purchase_order.user',
    ]).find(param?.id)
    setJob(data)
  }

  function handleOpenNoteModal() {
    setAddNoteModal(true)
  }

  function handleSetTextForModal(text: any) {
    setTextForModal(text)
  }

  function handleEditClick(id: any) {
    setTextForModal(id)
    history.push(location.pathname + `/edit`)
  }

  function handlePurchaseOrderClick(id: any) {
    history.push(`/purchase-orders/${id}`)
  }

  function handleAddPhoto(id: any) {
    setAddPhotoModal(true)
  }

  async function handleSubmitNewPhoto() {
    let fd = new FormData()
    fd.append('photos', inboundPhoto, inboundPhoto.name)
    let photoRes = await addAttachments(fd, job.id)
    if (photoRes) {
      setInboundPhoto(null)
      setAddPhotoModal(false)
    }
  }

  const hasPurchaseOrder = useMemo(() => job?.purchaseOrder, [job])

  async function handleSubmitNewText() {
    let nJob: any = await Job.find(job?.id)
    let newNote = new Note()
    newNote.text = text
    nJob.data.notes.push(newNote)
    const success = await nJob.data.save({ with: 'notes' })
    if (success) {
      getJob()
      setTextForModal(text)
      setAddNoteModal(false)
    }
  }

  async function handlePrintJobForm() {
    let url =
      'https://precision-po-public-pdfs.s3.amazonaws.com/NEW_JOB_SHEET_PDF_edited_2.pdf'
    let file = await fetch(url).then((res) => res.arrayBuffer())
    let pdf = await PDFDocument.load(file)
    let formToPrint: PDFDocument = jobToPdf(job, pdf)

    let pdfBytes = await formToPrint.save()
    let pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' })
    let pdfUrl = URL.createObjectURL(pdfBlob)
    window.open(pdfUrl)
  }

  const panes = [
    {
      menuItem: 'Notes',
      render: () => (
        <Tab.Pane attached={false}>
          <NotesTable purchaseOrder={job} openModal={handleOpenNoteModal} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Photos',
      render: () => (
        <Tab.Pane attached={false}>
          <JobPhotos job_id={job.id} inboundPhoto={inboundPhoto} />
        </Tab.Pane>
      ),
    },
  ]

  if (!job) return <></>

  const JobMenuDropdown = () => (
    <Dropdown text="Menu">
      <Dropdown.Menu>
        <Dropdown.Item
          icon="edit"
          text="Edit"
          onClick={() => handleEditClick(job.id)}
        ></Dropdown.Item>
        {job.purchaseOrder && (
          <Dropdown.Item
            icon="file"
            text="View Original Purchase Order"
            onClick={() => handlePurchaseOrderClick(job.purchaseOrder?.id)}
          />
        )}
        <Dropdown.Item onClick={() => handlePrintJobForm()}>
          <Icon name="file pdf outline" />
          Generate PDF
        </Dropdown.Item>
        <Dropdown.Item
          icon="camera"
          text="Add Photo"
          onClick={() => handleAddPhoto(job.id)}
        />
      </Dropdown.Menu>
    </Dropdown>
  )

  return (
    <Container>
      <Card fluid color="blue" style={{ padding: '1rem' }}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column textAlign="left">
              <JobMenuDropdown />
            </Grid.Column>
            <Grid.Column>
              <Card.Header>
                Job: {job?.id} for {job?.customer?.name}
              </Card.Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <Card fluid style={{ padding: '1rem' }}>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>
                  Type: <br />
                  {jobTypes[job.jobType]}
                </h2>
                <h2>
                  Customer: <br />
                  {!hasPurchaseOrder
                    ? job.customer?.name
                    : job.purchaseOrder?.customer?.name}
                </h2>
                <h2>
                  Customer Phone Number: <br />
                  {!hasPurchaseOrder
                    ? job.customer?.phone
                    : job.purchaseOrder?.customer?.phone}
                </h2>
                <h2>
                  Salesman: <br />
                  {!hasPurchaseOrder
                    ? job.user?.name
                    : job.purchaseOrder?.user?.name}
                </h2>
                <h2>
                  Vendor: <br />
                  {!hasPurchaseOrder
                    ? job.vendor &&
                      ` ${job.vendor?.name}${
                        job.vendor?.status === 'removed' ? ' (removed)' : ''
                      }`
                    : job.purchaseOrder?.vendor &&
                      ` ${job.purchaseOrder?.vendor.name}${
                        job.purchaseOrder?.vendor.status === 'removed'
                          ? ' (removed)'
                          : ''
                      }`}
                </h2>
                <h2>
                  Manufacturer: <br />
                  {!hasPurchaseOrder
                    ? job.manufacturer &&
                      `${job.manufacturer?.name}${
                        job.manufacturer?.status === 'removed'
                          ? ' (removed)'
                          : ''
                      }`
                    : job.purchaseOrder?.vendor &&
                      `${job.purchaseOrder?.manufacturer.name}${
                        job.purchaseOrder?.manufacturer.status === 'removed'
                          ? '(removed)'
                          : ''
                      }`}
                </h2>
              </div>
            </Grid.Column>
            {hasPurchaseOrder ? (
              <Grid.Column>
                <h2>Shipping Method: {job.purchaseOrder?.shippingMethod}</h2>
                <h2>Shipping Speed: {job.purchaseOrder?.shippingSpeed}</h2>
                <h2>
                  Created On:{' '}
                  {moment(job.purchaseOrder?.createdAt).format('ll')}
                </h2>
                <h2>
                  Updated On:{' '}
                  {moment(job.purchaseOrder?.updatedAt).format('ll')}
                </h2>
              </Grid.Column>
            ) : (
              <Grid.Column>
                <div className={styles.mobileText}>
                  <h2>Created On: {moment(job.createdAt).format('ll')}</h2>
                  <h2>Updated On: {moment(job.updatedAt).format('ll')}</h2>
                </div>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Card>
      {job && <Tab menu={{ secondary: true, pointing: true }} panes={panes} />}
      <Modal
        onClose={() => setAddNoteModal(false)}
        onOpen={() => setAddNoteModal(true)}
        open={addNoteModal}
      >
        <Modal.Header>Add a new note for PO: {job?.id}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form>
              <TextArea
                placeholder="Note"
                name="text"
                onChange={(e: any, data: any) =>
                  handleSetTextForModal(data.value)
                }
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setAddNoteModal(false)}>
            Nope
          </Button>
          <Button primary type="submit" onClick={handleSubmitNewText}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        onClose={() => {
          setInboundPhoto(null)
          setAddPhotoModal(false)
        }}
        onOpen={() => setAddPhotoModal(true)}
        open={addPhotoModal}
      >
        <Modal.Header>Add a new photo for Job: {job?.id}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form>
              <Input
                placeholder="Photo Url"
                name="text"
                type="file"
                onChange={(e: any, data: any) => {
                  setInboundPhoto(e.target.files[0])
                }}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              setInboundPhoto(null)
              setAddPhotoModal(false)
            }}
          >
            Nope
          </Button>
          <Button primary type="submit" onClick={handleSubmitNewPhoto}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  )
}

function NotesTable(props: any) {
  const { purchaseOrder, openModal } = props
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            Notes
            <Button
              icon
              labelPosition="left"
              onClick={openModal}
              floated="right"
            >
              <Icon name="plus" />
              Add Note
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {purchaseOrder.notes?.length > 0 &&
          purchaseOrder.notes?.map((note: any) => {
            return (
              <Table.Row key={note.id}>
                <Table.Cell>{note.text}</Table.Cell>
              </Table.Row>
            )
          })}
      </Table.Body>
    </Table>
  )
}

function JobPhotos(props: any) {
  const [photos, setPhotos] = useState<any>([])
  useEffect(() => {
    loadPhotos()
  }, [props.inboundPhoto])

  async function loadPhotos() {
    let data = await getAttachmentsForJob(props.job_id)
    data.job = JSON.parse(data.job)
    setPhotos(data.photos)
  }

  if (photos.length === 0) return <h2>No Photos</h2>
  return (
    <Image.Group size="large">
      {photos?.length > 0 &&
        photos?.map((photo: any, index: any) => {
          return (
            <Image
              key={index}
              href={`${url}${photo}`}
              target="_blank"
              src={`${url}${photo}`}
            />
          )
        })}
    </Image.Group>
  )
}
