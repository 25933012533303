import { useState, useEffect } from 'react'

function useMobile() {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    windowWidth()
  }, [isMobile])

  function windowWidth() {
    const { innerWidth: width } = window

    if (width < 440) {
      return setIsMobile(true)
    } else {
      return setIsMobile(false)
    }
  }
  return isMobile
}

export default useMobile
