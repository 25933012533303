import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Button, Container, Form, Label, Search } from 'semantic-ui-react'
import * as yup from 'yup'
import Customer from '../../models/customer'
import { useHistory } from 'react-router-dom'
import User from '../../models/user'
import _ from 'lodash'

let schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().nullable(),
  phone: yup.string().nullable(),
  address: yup.string().nullable(),
  state: yup.string().nullable(),
  city: yup.string().nullable(),
  county: yup.string().nullable(),
  country: yup.string().nullable(),
  zipcode: yup.string().nullable(),
  extension: yup.string().nullable(),
  contact: yup.object().nullable(),
  salesman: yup.object(),
})

function EditCustomer() {
  const param: any = useParams()
  const history: any = useHistory()
  const [customer, setCustomer] = useState<any>(null)
  useEffect(() => {
    initiatialize()
  }, [param])

  async function initiatialize() {
    if (!param) return
    let { data }: any = await Customer.includes(['contact', 'salesman']).find(
      param?.id
    )

    setCustomer(data)
  }

  if (!customer) return <></>

  return (
    <Container>
      <Formik
        initialValues={{
          name: customer.name,
          email: customer.email,
          phone: customer.phone,
          address: customer.address,
          city: customer.city,
          state: customer.state,
          county: customer.county,
          country: customer.country,
          zipcode: customer.zipcode,
          extension: customer.extension,
          contact: customer.contact,
          salesman: customer.salesman,
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(async () => {
            let sCus: any = await Customer.includes('contact').find(customer.id)

            sCus.data.name = values.name
            sCus.data.email = values.email
            sCus.data.address = values.address
            sCus.data.phone = values.phone
            sCus.data.city = values.city
            sCus.data.state = values.state
            sCus.data.county = values.county
            sCus.data.country = values.country
            sCus.data.extension = values.extension
            sCus.data.zipcode = values.zipcode

            if (values.contact?.id) {
              sCus.data.contact = new User({ id: values.contact?.id })
              sCus.data.contact.isPersisted = true
            }

            if (values.salesman?.id) {
              sCus.data.salesman = new User({ id: values.salesman?.id })
              sCus.data.salesman.isPersisted = true
            }

            // let user: any = await User.find(values.contact);
            // sCus.data.contact = user.data;

            const success = await sCus.data.save({
              with: ['contact.id', 'salesman.id'],
            })
            if (success) {
              history.push(`/customers/${sCus.data.id}`)
              setSubmitting(false)
            }
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }: /* and other goodies */
        any) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field
              name="name"
              label="Name"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name && touched.name && errors.name}
            />
            <Form.Field
              type="email"
              name="email"
              label="Email"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email && errors.email}
            />
            <Form.Field
              type="tel"
              name="phone"
              label="Phone"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              error={errors.phone && touched.phone && errors.phone}
            />
            <Form.Field
              name="extension"
              label="Extension"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.extension}
              error={errors.extension && touched.extension && errors.extension}
            />
            <Form.Field
              name="address"
              label="Address"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              error={errors.address && touched.address && errors.address}
            />
            <Form.Field
              name="city"
              label="City"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              error={errors.city && touched.city && errors.city}
            />
            <Form.Field
              name="state"
              label="State"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              error={errors.state && touched.state && errors.state}
            />
            <Form.Field
              name="county"
              label="County"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.county}
              error={errors.county && touched.county && errors.county}
            />
            <Form.Field
              name="country"
              label="Country"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              error={errors.country && touched.country && errors.country}
            />
            <Form.Field
              name="zipcode"
              label="Zipcode"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zipcode}
              error={errors.zipcode && touched.zipcode && errors.zipcode}
            />
            <Form.Field>
              <label>Contact: {values.contact?.name}</label>
              <UserSearch
                onChange={setFieldValue}
                fieldName="contact"
                value={values.contact?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Salesman: {values.salesman?.name}</label>
              <UserSearch
                onChange={setFieldValue}
                fieldName="salesman"
                value={values.salesman?.name}
                userType="employee"
              />
            </Form.Field>

            <Button primary type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

function UserSearch(props: any) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    if (!searchTerm) return
    let scope: any = User
    if (props.userType) {
      scope = scope.where({
        user_type: props.userType,
      })
    }
    let { data: userData }: any = await scope
      .where({
        status: 'active',
        name: { fuzzyMatch: searchTerm.replace('&', '') },
      })
      .all()
    setResults(userData)
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    if (props.value) onChange(props.fieldName, {})
    setSearchTerm(data.value || null)
  }, 600)

  function handleSelectItem(e: any, data: any) {
    onChange(props.fieldName, data.result)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a user name"
    />
  )
}

export default EditCustomer
