import { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router'
import {
  Table,
  Container,
  Pagination,
  Button,
  Icon,
  Input,
} from 'semantic-ui-react'
import Customer from '../../models/customer'
import ShowCustomer from './showcustomer'
import EditCustomer from './editCustomer'
import { useHistory, useLocation } from 'react-router-dom'
import QueryString from 'qs'
import NewCustomer from './newCustomer'
import _ from 'lodash'
import JobForCustomer from './jobForCustomer'
import styles from '../pages.module.scss'
import useMobile from '../../shared/hooks/useMobile'

export default function CustomerRoutes() {
  return (
    <Switch>
      <Route exact path="/customers">
        <CustomerListView />
      </Route>
      <Route exact path="/customers/new">
        <NewCustomer />
      </Route>
      <Route exact path="/customers/:id/edit">
        <EditCustomer />
      </Route>
      <Route exact path="/customers/:id/purchase_order">
        <EditCustomer />
      </Route>
      <Route exact path="/customers/:id/job">
        <JobForCustomer />
      </Route>
      <Route path="/customers/:id">
        <ShowCustomer />
      </Route>
    </Switch>
  )
}

function CustomerListView() {
  const param: any = useParams()
  const history: any = useHistory()
  const location: any = useLocation()
  const isMobile = useMobile()
  let search: any = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<any>(0)
  const [customers, setCustomers] = useState<any>([])
  useEffect(() => {
    getCustomers()
  }, [param])

  useEffect(() => {
    document.title = 'Customers | PFP'
  }, [])

  async function getCustomers() {
    setLoading(true)
    let scope: any = Customer
    scope = scope.page(search?.page).per(10).order({ name: 'asc' })

    if (search?.localSearchTerm) {
      scope = scope.where({ name: { fuzzyMatch: search?.localSearchTerm } })
    } else {
      scope = scope.stats({ total: 'count' })
    }
    let data = await scope.all()
    if (data.meta.stats) {
      setTotalPages(Math.ceil(data.meta.stats.total.count / 10))
    } else {
      setTotalPages(Math.ceil(data.data.length / 10))
    }
    setCustomers(data.data)
    setLoading(false)
  }

  async function handlePageChange(e: any, data: any) {
    search.page = data.activePage
    history.push(location.pathname + `?${QueryString.stringify(search)}`)
  }

  function handleEditClick(id: any) {
    history.push(location.pathname + `/${id}/edit`)
  }
  function handleShowClick(id: any) {
    history.push(location.pathname + `/${id}`)
  }

  function handleNewClick(id: any) {
    history.push(location.pathname + `/new`)
  }

  function handleNewPurchaseOrder(id: any) {
    history.push(`/purchase-orders/new/customer/${id}`)
  }
  function handleNewJob(id: any) {
    history.push(location.pathname + `/${id}/job`)
  }

  const handleSearch = _.debounce(async (e: any) => {
    let query: any = {}
    if (search?.page) {
      query.page = search?.page
    }
    query.localSearchTerm = e.target?.value
    history.push(location.pathname + `?${QueryString.stringify(query)}`)
  }, 600)
  return (
    <Container>
      <Input
        onChange={handleSearch}
        defaultValue={search?.localSearchTerm}
        fluid
        icon="search"
        iconPosition="left"
        loading={loading}
        placeholder="Search by customer name, email, or phone number"
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>
              <Button
                positive
                icon
                fluid
                labelPosition="left"
                onClick={handleNewClick}
              >
                <Icon name="plus" />
                New Customer
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {customers.map((customer: any) => {
            return (
              <Table.Row key={customer.id}>
                <Table.Cell>{customer.id}</Table.Cell>
                <Table.Cell width="3">{customer.name}</Table.Cell>
                <Table.Cell width="3">{customer.email}</Table.Cell>
                <Table.Cell width="3">{customer.phone}</Table.Cell>
                <Table.Cell width="3">{customer.status}</Table.Cell>
                <Table.Cell>
                  <div>
                    <Button
                      icon
                      fluid
                      className={styles.routesButton}
                      labelPosition="left"
                      onClick={() => handleEditClick(customer.id)}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>
                    <Button
                      icon
                      fluid
                      labelPosition="left"
                      onClick={() => handleShowClick(customer.id)}
                    >
                      <Icon name="book" />
                      View
                    </Button>
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <Button
                      primary
                      icon
                      fluid
                      className={styles.routesButton}
                      labelPosition="left"
                      onClick={() => handleNewPurchaseOrder(customer.id)}
                    >
                      <Icon name="file" />
                      New PO
                    </Button>
                    <Button
                      secondary
                      icon
                      fluid
                      labelPosition="left"
                      onClick={() => handleNewJob(customer.id)}
                    >
                      <Icon name="file alternate outline" />
                      New Job
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {totalPages > 0 ? (
        <Pagination
          size={isMobile ? 'mini' : ''}
          ellipsisItem={false}
          defaultActivePage={search.page || 1}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          siblingRange={isMobile ? -1 : 1}
        />
      ) : (
        <></>
      )}
    </Container>
  )
}
