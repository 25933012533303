import { Attr, Model } from 'spraypaint'
import ApplicationRecord from './applicationRecord'

@Model()
class Note extends ApplicationRecord {
  static jsonapiType = 'notes'
  @Attr() text: string
  @Attr() notable_id: number
  @Attr() notable_type: string
}

export default Note
