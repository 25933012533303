import { Attr, Model } from 'spraypaint'
import ApplicationRecord from './applicationRecord'

@Model()
class User extends ApplicationRecord {
  static jsonapiType = 'users'
  @Attr() name: string
  @Attr() email: string
  @Attr() phone: string
  @Attr() address: string

  @Attr() city: string
  @Attr() state: string
  @Attr() zipcode: string
  @Attr() county: string
  @Attr() country: string
  @Attr() extension: string

  @Attr() title: string
  @Attr() initials: string
  @Attr() userType: string

  @Attr() status: string
}

export default User
