import { Attr, HasMany, HasOne, Model } from 'spraypaint'
import ApplicationRecord from './applicationRecord'
import Customer from './customer'
import Vendor from './vendor'
import Note from './note'
import User from './user'
import Manufacturer from './manufacturer'
import PurchaseOrder from './purchase_order'

@Model()
class Job extends ApplicationRecord {
  static jsonapiType = 'jobs'

  @Attr() status: string
  @HasOne() customer: Customer
  @HasOne() manufacturer: Manufacturer
  @HasOne() purchaseOrder: PurchaseOrder
  @HasOne() user: User
  @HasOne() vendor: Vendor
  @HasMany() notes: Note[]
  @Attr({ persist: false }) createdAt: string
  @Attr({ persist: false }) finishedAt: string
  @Attr() state: string
  @Attr() jobType: string

  get specialId(): any {
    return this.id
  }
}

export default Job
