import _ from 'lodash'
import { useState, useEffect } from 'react'
import { Button, Header, Label, Modal, Search } from 'semantic-ui-react'
import Vendor from '../../models/vendor'
import VendorModal from '../../pages/vendors/vendorModal'
import styles from '../components.module.scss'

interface VendorSearchProps {
  value: any
  onChange: (key: string, value: object) => any
}

export function VendorSearch(props: VendorSearchProps) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const [vendorModalOpen, setVendorModal] = useState<any>(false)

  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    let { data: vendorData }: any = await Vendor.where({
      name: { fuzzyMatch: searchTerm.replace('&', '') },
    }).all()
    setResults(vendorData)
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    if (props.value) onChange('vendor', {})
    setSearchTerm(data.value)
  }, 600)

  function handleSelectItem(e: any, data: any) {
    onChange('vendor', data.result)
    setSearchTerm('')
  }

  return (
    <div className={styles.vendorSearch}>
      <Search
        loading={loading}
        onResultSelect={handleSelectItem}
        onSearchChange={handleSearchChange}
        noResultsMessage={
          <p onClick={() => setVendorModal(true)}>Add New Vendor</p>
        }
        resultRenderer={resultRenderer}
        results={results}
        value={props.value}
        style={{ flex: 1 }}
        placeholder="Enter a vendor name"
      />
      <Button onClick={() => setVendorModal(true)}>Add New Vendor</Button>
      <Modal onClose={() => setVendorModal(false)} open={vendorModalOpen}>
        <Modal.Content>
          <Modal.Description>
            <Header>Add New Vendor</Header>

            <VendorModal
              handleCloseModal={(ven: any) => {
                if (ven) handleSelectItem(null, { result: ven })
                setVendorModal(false)
              }}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </div>
  )
}
