import { useParams } from 'react-router'
import Vendor from '../../models/vendor'
import { useEffect, useState } from 'react'
import { Button, Card, Container, Grid, Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import styles from '../pages.module.scss'

export default function ShowVendor() {
  const param: any = useParams()
  const history: any = useHistory()
  const [vendor, setVendor] = useState<any>(null)
  useEffect(() => {
    getVendor()
  }, [param])

  async function getVendor() {
    if (!param) return
    let { data }: any = await Vendor.find(param?.id)
    setVendor(data)
  }

  function handleEditClick(id: any) {
    history.push(`/vendors/${id}/edit`)
  }

  async function handleDelete(vendor: any) {
    const r = window.confirm(`Are you sure you want to delete: ${vendor.name}`)
    if (r) {
      const { data: ven } = await Vendor.find(vendor.id)
      ven.isPersisted = true
      ven.status = 'removed'
      await ven.save()
      getVendor()
    }
  }

  return (
    <Container>
      <Card fluid color="blue" style={{ padding: '1rem' }}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Card.Header>{vendor?.name}</Card.Header>
            </Grid.Column>
            <Grid.Column>
              <Button
                icon
                labelPosition="left"
                onClick={() => handleEditClick(vendor.id)}
              >
                <Icon name="edit" />
                Edit
              </Button>
              {vendor?.status !== 'removed' && (
                <Button
                  icon
                  negative
                  labelPosition="left"
                  onClick={() => handleDelete(vendor)}
                >
                  <Icon name="delete" />
                  Delete
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <Card fluid style={{ padding: '1rem' }}>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>
                  Vendor: <br /> {vendor?.id}
                </h2>
                <h2>
                  Name: <br /> {vendor?.name}
                </h2>
                <h2>
                  Email: <br /> {vendor?.email}
                </h2>
                <h2>
                  Phone: <br /> {vendor?.phone}
                </h2>
                <h2>
                  Status: <br /> {vendor?.status}
                </h2>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>Address: {vendor?.address}</h2>
                <h2>City: {vendor?.city}</h2>
                <h2>State: {vendor?.state}</h2>
                <h2>County: {vendor?.county}</h2>
                <h2>Country: {vendor?.country}</h2>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}
