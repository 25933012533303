import { PDFDocument, PDFForm } from 'pdf-lib'
import Job from '../../models/job'
import moment from 'moment'

// this function mutates the form (setting field values which results in bytes)
export function jobToPdf(job: Job, pdf: PDFDocument): PDFDocument {
  let form = pdf.getForm()

  if (job.user) {
    findAndSetFieldValue('Salesman', job.user.name, form)
  }

  // PO
  if (job.purchaseOrder) {
    job.purchaseOrder.id &&
      findAndSetFieldValue('PO', job.purchaseOrder.id, form)

    // Purchase Order Date
    findAndSetFieldValue(
      'OrderMonth',
      moment
        .utc(job.purchaseOrder.createdAt, 'YYYY-MM-DD hh:mm:ss')
        .format('MM'),
      form
    )
    findAndSetFieldValue(
      'OrderDay',
      moment
        .utc(job.purchaseOrder.createdAt, 'YYYY-MM-DD hh:mm:ss')
        .format('DD'),
      form
    )
    findAndSetFieldValue(
      'OrderYear',
      moment
        .utc(job.purchaseOrder.createdAt, 'YYYY-MM-DD hh:mm:ss')
        .format('yyyy'),
      form
    )

    if (job.purchaseOrder?.user) {
      findAndSetFieldValue('Salesman', job.purchaseOrder.user.name, form)
    }
  }

  // Order Date
  findAndSetFieldValue(
    'RecievedMonth',
    moment.utc(job.createdAt, 'YYYY-MM-DD hh:mm:ss').format('MM'),
    form
  )
  findAndSetFieldValue(
    'RecievedDay',
    moment.utc(job.createdAt, 'YYYY-MM-DD hh:mm:ss').format('DD'),
    form
  )
  findAndSetFieldValue(
    'RecievedYear',
    moment(job?.purchaseOrder?.createdAt).format('yyyy'),
    form
  )

  job.customer?.name &&
    findAndSetFieldValue('company', job.customer?.name, form)

  // Company Name
  if (job.customer?.phone) {
    if (job.customer?.phone.length > 10) {
      const phoneAreaCode = job.customer.phone.substring(0, 3)
      const phoneNumber = job.customer.phone.substring(4, 12)
      findAndSetFieldValue('company_area_code', phoneAreaCode, form)
      findAndSetFieldValue('company_phone_number', phoneNumber, form)
    } else {
      findAndSetFieldValue('company_area_code', '405', form)
      findAndSetFieldValue('company_phone_number', job.customer.phone, form)
    }
  }

  if (job.manufacturer) {
    findAndSetFieldValue('MFG_NAME', job.manufacturer.name, form)
  }

  // notes
  if (job.notes) {
    let concattedNotes = job.notes.map((note) => note.text).join('\n')
    findAndSetFieldValue('notes', concattedNotes, form)
  }

  // Company Contact
  job.customer?.contact &&
    findAndSetFieldValue('contact', job.customer.contact.name, form)
  job.customer?.contact?.phone &&
    findAndSetFieldValue(
      'company_contact_phone_number',
      job.customer.contact.phone,
      form
    )
  job.customer?.address &&
    findAndSetFieldValue('SHIP', job.customer.address, form)

  job.customer?.city && findAndSetFieldValue('CITY_2', job.customer.city, form)
  job.customer?.state && findAndSetFieldValue('ST_2', job.customer.state, form)
  job.customer?.zipcode &&
    findAndSetFieldValue('ZIP_2', job.customer.zipcode, form)

  // Job Id
  job.id && findAndSetFieldValue('JOB', job.id, form)

  // Shipping Type
  job.id &&
    findAndSetFieldValue(
      'shipping_type',
      job.purchaseOrder?.shippingMethod,
      form
    )

  return pdf
}

function findAndSetFieldValue(
  fieldName: string,
  fieldValue: string,
  form: PDFForm
) {
  const txtField = form.getTextField(fieldName)
  if (txtField) {
    txtField.setText(fieldValue)
  }
}
