import { Attr, Model } from 'spraypaint'
import ApplicationRecord from './applicationRecord'

@Model()
class Vendor extends ApplicationRecord {
  static jsonapiType = 'vendors'
  @Attr() name: string
  @Attr() email: string
  @Attr() address: string
  @Attr() phone: string

  @Attr() status: string
  @Attr() county: string
  @Attr() state: string
  @Attr() city: string
  @Attr() country: string
}

export default Vendor
