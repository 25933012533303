import { Model, SpraypaintBase } from 'spraypaint'
import url from '../utils/base_url'
@Model()
class ApplicationRecord extends SpraypaintBase {
  static baseUrl = url + '/api/v1'
  static apiNamespace = ''
}

ApplicationRecord.generateAuthHeader = (token: string) => {
  return 'Bearer ' + token
}

export default ApplicationRecord
