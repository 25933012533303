export const userTypeToText: any = {
  0: 'Employee',
  1: 'Customer',
  2: 'Vendor',
  3: 'Manufacturer',
  4: 'Salesman',
}

export const userTextToType: any = {
  Employee: 0,
  Customer: 1,
  Vendor: 2,
  Manufacturer: 3,
  Salesman: 4,
}
