import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import {
  Table,
  Container,
  Grid,
  Button,
  Icon,
  Statistic,
  ButtonGroup,
} from 'semantic-ui-react'
import Job from '../../models/job'
import moment from 'moment'
import styles from '../pages.module.scss'
import { useJobsFeedChannel } from 'pages/jobs/hooks/useJobsChannel'
import useMobile from 'shared/hooks/useMobile'
import { useHistory } from 'react-router-dom'

type validJobStates = 'pending' | 'in_progress' | 'finished'

export default function Dashboard() {
  const param = useParams()
  const isMobile = useMobile()
  const history: any = useHistory()
  const [jobs, setJobs] = useState<Job[]>([])

  const jobsRef = useRef({})
  jobsRef.current = jobs

  useEffect(() => {
    document.title = 'Dashboard | PFP'
  }, [])

  useEffect(() => {
    getJobs()
  }, [param, isMobile])

  useJobsFeedChannel({
    handleUpdatedJobsPayload: handleUpdatedJobs,
    handleRemovedJobPayload: handleRemovedJob,
  })

  async function getJobs() {
    // limit pending to 50-100 jobs
    const pendingScope = Job.order('created_at')
      .includes(['customer'])
      .where({
        status: 'active',
        job_type: ['unassigned', 'pump', 'other'],
        state: 'pending',
      })
      .page(1)
      .per(isMobile ? 50 : 100)

    // limit in progress to 1000 jobs
    const inProgressScope = Job.order('created_at')
      .includes(['customer'])
      .where({
        status: 'active',
        job_type: ['unassigned', 'pump', 'other'],
        state: 'in_progress',
      })
      .page(1)
      .per(1000)

    // limit finished to jobs within the last 7 days
    const finishedScope = Job.order('finished_at')
      .includes(['customer'])
      .where({
        status: 'active',
        job_type: ['unassigned', 'pump', 'other'],
        state: 'finished',
        finished_at: 'last_week',
      })
      .page(1)
      .per(1000)

    const [pendingCollection, inProgressCollection, finishedCollection] =
      await Promise.all([
        pendingScope.all(),
        inProgressScope.all(),
        finishedScope.all(),
      ])

    setJobs([
      ...pendingCollection.data,
      ...inProgressCollection.data,
      ...finishedCollection.data,
    ])
  }

  function handleUpdatedJobs(job: Job) {
    setJobs([
      ...(jobsRef.current as Job[]).filter(
        (j: Job) => parseInt(j.id!) !== parseInt(job.id!)
      ),
      job,
    ])
  }

  function handleRemovedJob(jobId: number) {
    setJobs([
      ...(jobsRef.current as Job[]).filter(
        (j: Job) => parseInt(j.id!) !== jobId
      ),
    ])
  }

  function handleShowClick(id: any) {
    history.push(`jobs/${id}`)
  }

  const pending = useMemo(
    () =>
      jobs
        .filter((x) => x.state === 'pending')
        .sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))),
    [jobs]
  )

  const inProgress = useMemo(
    () =>
      jobs
        .filter((x) => x.state === 'in_progress')
        .sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))),
    [jobs]
  )

  const finished = useMemo(
    () =>
      jobs
        .filter((x) => x.state === 'finished')
        .sort((a, b) => moment(a.finishedAt).diff(moment(b.finishedAt))),
    [jobs]
  )

  const updateJobState = async (id: any, state: validJobStates) => {
    let newJobStatus = await Job.find(id)
    const formattedState = state.toLowerCase().replaceAll(' ', '_')

    newJobStatus.data.state = formattedState
    const success = await newJobStatus.data.save()
    if (success) getJobs()
  }

  return (
    <Container className={styles.dashboardContainer}>
      <Grid columns={3} stackable>
        <Grid.Column textAlign="center">
          <Statistic>
            <Statistic.Value
              data-testid="pending-count"
              href={isMobile ? '#pending' : ''}
              style={isMobile ? { color: 'darkBlue' } : null}
            >
              {pending.length}
            </Statistic.Value>
            <Statistic.Label>Pending</Statistic.Label>
          </Statistic>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Statistic>
            <Statistic.Value
              data-testid="inprogress-count"
              href={isMobile ? '#in_progress' : ''}
              style={isMobile ? { color: 'darkBlue' } : null}
            >
              {inProgress.length}
            </Statistic.Value>
            <Statistic.Label>In Progress</Statistic.Label>
          </Statistic>
        </Grid.Column>
        <Grid.Column textAlign="center">
          <Statistic>
            <Statistic.Value
              data-testid="finished-count"
              href={isMobile ? '#finished' : ''}
              style={isMobile ? { color: 'darkBlue' } : null}
            >
              {finished.length}
            </Statistic.Value>
            <Statistic.Label>Finished</Statistic.Label>
          </Statistic>
        </Grid.Column>
      </Grid>
      <Grid columns={3} stackable>
        <Grid.Column id="pending">
          <Table size="small" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Customer</Table.HeaderCell>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {pending.map((job: Job) => {
                const nextState: validJobStates = 'in_progress'
                function handleChangeById(e: any) {
                  e.preventDefault()
                  return updateJobState(job.id, nextState)
                }

                return (
                  <Table.Row key={job.id}>
                    <Table.Cell width="1">
                      <button
                        onClick={() => handleShowClick(job.id)}
                        className={styles.linkButton}
                      >
                        {job.id}
                      </button>
                    </Table.Cell>
                    <Table.Cell width="3">{job.customer?.name}</Table.Cell>
                    <Table.Cell width="1">
                      {moment
                        .utc(job.createdAt, 'YYYY-MM-DD hh:mm:ss')
                        .format('MM/DD')}
                    </Table.Cell>
                    <Table.Cell width="2">
                      <Button color="blue" icon onClick={handleChangeById}>
                        <Icon name="arrow circle right" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column>
          <Table size="small" celled>
            <Table.Header id="in_progress">
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Customer</Table.HeaderCell>
                <Table.HeaderCell>Created</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {inProgress.map((job: Job) => {
                function handleChangeToPending(e: any) {
                  e.preventDefault()
                  let pendingState: validJobStates = 'pending'
                  return updateJobState(job.id, pendingState)
                }

                function handleChangeToFinished(e: any) {
                  e.preventDefault()
                  let finishedState: validJobStates = 'finished'
                  return updateJobState(job.id, finishedState)
                }

                return (
                  <Table.Row key={job.id}>
                    <Table.Cell width="1">
                      <button
                        onClick={() => handleShowClick(job.id)}
                        className={styles.linkButton}
                      >
                        {job.id}
                      </button>
                    </Table.Cell>
                    <Table.Cell width="3">{job.customer?.name}</Table.Cell>
                    <Table.Cell width="1">
                      {moment
                        .utc(job.createdAt, 'YYYY-MM-DD hh:mm:ss')
                        .format('MM/DD')}
                    </Table.Cell>
                    <Table.Cell width="1">
                      <Button.Group icon>
                        <Button onClick={handleChangeToPending} color="yellow">
                          <Icon name="arrow circle left" />
                        </Button>
                        <Button.Or />
                        <Button positive onClick={handleChangeToFinished}>
                          <Icon name="check circle" />
                        </Button>
                      </Button.Group>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column id="finished">
          <Table size="small" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>Customer</Table.HeaderCell>
                <Table.HeaderCell>Finished</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {finished.map((job: Job) => {
                function handleChangeToPending(e: any) {
                  e.preventDefault()
                  let prevState: validJobStates = 'in_progress'
                  return updateJobState(job.id, prevState)
                }
                return (
                  <Table.Row key={job.id}>
                    <Table.Cell width="1">
                      <button
                        onClick={() => handleShowClick(job.id)}
                        className={styles.linkButton}
                      >
                        {job.id}
                      </button>
                    </Table.Cell>
                    <Table.Cell width="3">{job.customer?.name}</Table.Cell>
                    <Table.Cell width="1">
                      {moment
                        .utc(job.finishedAt, 'YYYY-MM-DD hh:mm:ss')
                        .format('MM/DD')}
                    </Table.Cell>
                    <Table.Cell width="1">
                      <Button
                        icon
                        onClick={handleChangeToPending}
                        color="yellow"
                        id={job.id}
                        value={'in_progress'}
                      >
                        <Icon name="arrow circle left" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </Container>
  )
}
