import { useParams } from 'react-router'
import User from '../../models/user'
import { useEffect, useState } from 'react'
import { Container, Card, Icon, Grid, Button } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import styles from '../pages.module.scss'

export default function ShowUser() {
  const param: any = useParams()
  const history: any = useHistory()
  const [user, setUser] = useState<any>(null)
  useEffect(() => {
    getUser()
  }, [param])

  async function getUser() {
    if (!param) return
    let { data }: any = await User.find(param?.id)
    setUser(data)
  }

  function handleEditClick(id: any) {
    history.push(`/users/${id}/edit`)
  }

  return (
    <Container>
      <Card fluid color="blue" style={{ padding: '1rem' }}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Card.Header>{user?.name}</Card.Header>
            </Grid.Column>
            <Grid.Column>
              <Button
                icon
                labelPosition="left"
                onClick={() => handleEditClick(user.id)}
              >
                <Icon name="edit" />
                Edit
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <Card fluid style={{ padding: '1rem' }}>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>
                  User: <br /> {user?.id}
                </h2>
                <h2>
                  Name: <br /> {user?.name}
                </h2>
                <h2>
                  Email: <br /> {user?.email}
                </h2>
                <h2>
                  Status: <br /> {user?.status}
                </h2>
                <h2>
                  User Type: <br /> {user?.userType}
                </h2>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>
                  Address: <br /> {user?.address}
                </h2>
                <h2>
                  City: <br /> {user?.city}
                </h2>
                <h2>
                  State: <br /> {user?.state}
                </h2>
                <h2>
                  County: <br /> {user?.county}
                </h2>
                <h2>
                  Country:
                  <br /> {user?.country}
                </h2>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}
