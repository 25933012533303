import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Button, Container, Form } from 'semantic-ui-react'
import * as yup from 'yup'
import User from '../../models/user'
import { useHistory } from 'react-router-dom'
import { userTextToType } from '../../shared/utils/userTypeUtil'

let schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email(),
  phone: yup.string().nullable(),
  title: yup.string().nullable(),
  address: yup.string().nullable(),
  state: yup.string().nullable(),
  city: yup.string().nullable(),
  county: yup.string().nullable(),
  country: yup.string().nullable(),
  zipcode: yup.string().nullable(),
})

const userOptions = Object.keys(userTextToType).map((item) => {
  return {
    key: item,
    value: item.toLowerCase(),
    text: item,
  }
})

export default function EditUser() {
  const param: any = useParams()
  const history: any = useHistory()
  const [user, setUser] = useState<any>(null)
  useEffect(() => {
    getUser()
  }, [param])

  async function getUser() {
    if (!param) return
    let { data }: any = await User.find(param?.id)
    setUser(data)
  }
  if (!user) return <></>
  return (
    <Container>
      <Formik
        initialValues={user.attributes}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(async () => {
            let nUser: any = await User.find(user.id)
            nUser.data.name = values.name || user.attributes.name
            nUser.data.email = values.email || user.attributes.email

            nUser.data.phone = values.phone || user.attributes.phone
            nUser.data.status = values.status || user.attributes.status

            nUser.data.title = values.title || user.attributes.title
            nUser.data.userType = values.userType || user.attributes.userType

            nUser.data.address = values.address || user.attributes.address
            nUser.data.city = values.city || user.attributes.city
            nUser.data.state = values.state || user.attributes.state
            nUser.data.country = values.country || user.attributes.country
            nUser.data.zipcode = values.zipcode || user.attributes.zipcode
            nUser.data.county = values.county || user.attributes.county

            const success = await nUser.data.save()

            if (success) {
              history.push(`/users/${nUser.data.id}`)
              setSubmitting(false)
            }
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }: /* and other goodies */
        any) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field
              name="name"
              label="Name"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name && touched.name && errors.name}
            />
            <Form.Field
              type="email"
              name="email"
              label="Email"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email && errors.email}
            />
            <Form.Field
              type="tel"
              name="phone"
              label="Phone"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              error={errors.phone && touched.phone && errors.phone}
            />
            <Form.Field
              name="title"
              label="Title"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              error={errors.title && touched.title && errors.title}
            />
            <Form.Field
              name="address"
              label="Address"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              error={errors.address && touched.address && errors.address}
            />
            <Form.Field
              name="city"
              label="City"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              error={errors.city && touched.city && errors.city}
            />
            <Form.Field
              name="state"
              label="State"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              error={errors.state && touched.state && errors.state}
            />
            <Form.Field
              name="county"
              label="County"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.county}
              error={errors.county && touched.county && errors.county}
            />
            <Form.Field
              name="country"
              label="Country"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              error={errors.country && touched.country && errors.country}
            />
            <Form.Field
              name="zipcode"
              label="Zipcode"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zipcode}
              error={errors.zipcode && touched.zipcode && errors.zipcode}
            />
            <Form.Select
              label="User Type"
              name="userType"
              placeholder="Select User Type"
              options={userOptions}
              onChange={(e: any, data: any) => {
                setFieldValue('userType', data.value)
              }}
              defaultValue={user.userType}
            />

            <Button primary type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
