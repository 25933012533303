import { useParams } from 'react-router'
import Customer from '../../models/customer'
import { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Container,
  Form,
  Grid,
  Icon,
  Modal,
  Tab,
  Table,
  TextArea,
} from 'semantic-ui-react'
import { useHistory, useLocation } from 'react-router-dom'
import Note from '../../models/note'
import PurchaseOrder from '../../models/purchase_order'
import Job from '../../models/job'
import styles from '../pages.module.scss'

export default function ShowCustomer() {
  const param: any = useParams()
  const history: any = useHistory()
  const location: any = useLocation()
  const [text, setTextForModal] = useState('')
  const [addNoteModal, setAddNoteModal] = useState(false)
  const [customer, setCustomer] = useState<any>(null)
  useEffect(() => {
    getCustomer()
  }, [param])

  async function getCustomer() {
    if (!param) return

    let poScope = PurchaseOrder.includes('user').where({ status: 'active' })
    let jobsScope = Job.includes('purchase_order').where({ status: 'active' })

    let { data }: any = await Customer.includes([
      'notes',
      'purchase_orders',
      'contact',
      'salesman',
      'jobs',
    ])
      .merge({ purchase_orders: poScope, jobs: jobsScope })
      .find(param?.id)
    setCustomer(data)
  }

  function handleEditClick(id: any) {
    history.push(`/customers/${id}/edit`)
  }
  function handlePOClick(id: any) {
    history.push(`/purchase-orders/new/customer/${id}`)
  }

  function handleNewJob(id: any) {
    history.push(location.pathname + `/job`)
  }

  function handleViewPO(id: any) {
    history.push(`/purchase-orders/${id}`)
  }

  function handleViewJob(id: any) {
    history.push(`/jobs/${id}`)
  }

  function handleOpenNoteModal() {
    setAddNoteModal(true)
  }

  function handleSetTextForModal(text: any) {
    setTextForModal(text)
  }

  async function handleSubmitNewText() {
    let sCus: any = await Customer.find(customer.id)
    let newNote = new Note()
    newNote.text = text
    sCus.data.notes.push(newNote)
    const success = await sCus.data.save({ with: 'notes' })
    if (success) {
      getCustomer()
      setTextForModal(text)
      setAddNoteModal(false)
    }
  }

  const panes = [
    {
      menuItem: 'Purchase Orders',
      render: () => (
        <Tab.Pane attached={false}>
          <PurchaseOrderTables
            customer={customer}
            handleViewPO={handleViewPO}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Jobs',
      render: () => (
        <Tab.Pane attached={false}>
          <JobsTable customer={customer} handleViewJob={handleViewJob} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Notes',
      render: () => (
        <Tab.Pane attached={false}>
          <NotesTable customer={customer} openModal={handleOpenNoteModal} />
        </Tab.Pane>
      ),
    },
  ]

  return (
    <Container>
      <Card fluid color="blue" style={{ padding: '1rem' }}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Card.Header>{customer?.name}</Card.Header>
            </Grid.Column>
            <Grid.Column>
              <Button
                icon
                labelPosition="left"
                floated="right"
                className={styles.customerButton}
                onClick={() => handleEditClick(customer.id)}
              >
                <Icon name="edit" />
                Edit
              </Button>
              <Button
                primary
                icon
                floated="right"
                labelPosition="left"
                className={styles.customerButton}
                onClick={() => handlePOClick(customer.id)}
              >
                <Icon name="file" />
                New PO
              </Button>
              <Button
                positive
                icon
                floated="right"
                labelPosition="left"
                className={styles.customerButton}
                onClick={() => handleNewJob(customer.id)}
              >
                <Icon name="file" />
                New Job
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <Card fluid style={{ padding: '1rem' }}>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>Customer: {customer?.id}</h2>
                <h2>Name: {customer?.name}</h2>
                <h2>Phone Number: {customer?.phone}</h2>
                <h2>Email: {customer?.email}</h2>
                <h2>Address: {customer?.address}</h2>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>Contact Name: {customer?.contact?.name}</h2>
                <h2>Salesman Name: {customer?.salesman?.name}</h2>
                <h2>Status: {customer?.status}</h2>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      {customer && (
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      )}
      <Modal
        onClose={() => setAddNoteModal(false)}
        onOpen={() => setAddNoteModal(true)}
        open={addNoteModal}
      >
        <Modal.Header>
          Add a new note for {customer?.name || customer?.id}
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form>
              <TextArea
                placeholder="Note"
                name="text"
                onChange={(e: any, data: any) =>
                  handleSetTextForModal(data.value)
                }
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setAddNoteModal(false)}>
            Nope
          </Button>
          <Button primary type="submit" onClick={handleSubmitNewText}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  )
}

function NotesTable(props: any) {
  const { customer, openModal } = props
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            Notes
            <Button
              icon
              labelPosition="left"
              onClick={openModal}
              floated="right"
            >
              <Icon name="plus" />
              Add Note
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {customer.notes?.length > 0 &&
          customer.notes?.map((note: any) => {
            return (
              <Table.Row key={note.id}>
                <Table.Cell>{note.text}</Table.Cell>
              </Table.Row>
            )
          })}
      </Table.Body>
    </Table>
  )
}

function PurchaseOrderTables(props: any) {
  const { customer } = props

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>PO#</Table.HeaderCell>
          <Table.HeaderCell>Salesman</Table.HeaderCell>
          <Table.HeaderCell>Created At</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {customer.purchaseOrders?.length > 0 &&
          customer.purchaseOrders
            ?.sort((a: any, b: any) => b.id - a.id)
            .map((po: any) => {
              return (
                <Table.Row key={po.id}>
                  <Table.Cell>{po.id}</Table.Cell>
                  <Table.Cell>{po?.user?.name}</Table.Cell>
                  <Table.Cell>{po.createdAt}</Table.Cell>
                  <Table.Cell>
                    <div>
                      <Button
                        icon
                        labelPosition="left"
                        onClick={() => props.handleViewPO(po.id)}
                      >
                        <Icon name="file" />
                        View
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              )
            })}
      </Table.Body>
    </Table>
  )
}

function JobsTable(props: any) {
  const { customer } = props

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.HeaderCell>Related PO</Table.HeaderCell>
          <Table.HeaderCell>Created Date</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {customer.jobs?.length > 0 &&
          customer.jobs
            ?.sort((a: any, b: any) => b.id - a.id)
            .map((job: any) => {
              return (
                <Table.Row key={job.id}>
                  <Table.Cell>{job.id}</Table.Cell>
                  <Table.Cell>{job.purchaseOrder?.id}</Table.Cell>
                  <Table.Cell>{job.createdAt}</Table.Cell>
                  <Table.Cell>
                    <div>
                      <Button
                        icon
                        labelPosition="left"
                        onClick={() => props.handleViewJob(job.id)}
                      >
                        <Icon name="file" />
                        View
                      </Button>
                    </div>
                  </Table.Cell>
                </Table.Row>
              )
            })}
      </Table.Body>
    </Table>
  )
}
