import { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router'
import {
  Table,
  Container,
  Pagination,
  Button,
  Icon,
  Input,
} from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import QueryString from 'qs'
import _ from 'lodash'
import PurchaseOrder from '../../models/purchase_order'
import PurchaseOrderNew from './purchaseOrderNew'
import PurchaseOrderShow from './purchaseOrderShow'
import PurchaseOrderEdit from './purchaseOrderEdit'
import JobForPurchaseOrder from '../jobs/jobForPuchaseOrder'
import PurchaseOrderByCustomer from './purchaseOrderByCustomer'
import PurchaseOrderFromJob from './purchaseOrderFromJob'
import moment from 'moment'
import styles from '../pages.module.scss'
import useMobile from '../../shared/hooks/useMobile'

export default function PurchaseOrderRoutes() {
  return (
    <Switch>
      <Route exact path="/purchase-orders">
        <POListView />
      </Route>

      <Route exact path="/purchase-orders/new">
        <PurchaseOrderNew />
      </Route>

      <Route exact path="/purchase-orders/new/customer/:id">
        <PurchaseOrderByCustomer />
      </Route>
      <Route exact path="/purchase-orders/job/:id/new">
        <PurchaseOrderFromJob />
      </Route>
      <Route exact path="/purchase-orders/:id/edit">
        <PurchaseOrderEdit />
      </Route>
      <Route exact path="/purchase-orders/:id/purchase_order">
        <POListView />
      </Route>
      <Route exact path="/purchase-orders/:id/job">
        <JobForPurchaseOrder />
      </Route>
      <Route path="/purchase-orders/:id">
        <PurchaseOrderShow />
      </Route>
    </Switch>
  )
}

function POListView() {
  const param: any = useParams()
  const history: any = useHistory()
  const location: any = useLocation()
  const isMobile = useMobile()
  let search: any = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<any>(0)
  const [pos, setPOs] = useState<any>([])
  useEffect(() => {
    getPOs()
  }, [param])

  useEffect(() => {
    document.title = 'Purchase Orders | PFP'
  }, [])

  async function getPOs() {
    setLoading(true)
    let scope: any = PurchaseOrder
    scope = scope
      .includes(['customer', 'user', 'vendor'])
      .where({ status: 'active' })
      .page(search?.page)
      .per(10)
      .stats({ total: 'count' })
      .order({ id: 'desc' })

    if (search?.localSearchTerm) {
      scope = scope.where({ id: { fuzzyMatch: search?.localSearchTerm } })
    }

    let data = await scope.all()
    if (data.meta.stats) {
      setTotalPages(Math.ceil(data.meta.stats.total.count / 10))
    } else {
      setTotalPages(Math.ceil(data.data.length / 10))
    }
    setPOs(data.data)
    setLoading(false)
  }

  async function handlePageChange(e: any, data: any) {
    search.page = data.activePage
    history.push(location.pathname + `?${QueryString.stringify(search)}`)
  }

  function handleEditClick(id: any) {
    history.push(location.pathname + `/${id}/edit`)
  }

  function handleShowClick(id: any) {
    history.push(location.pathname + `/${id}`)
  }

  function handleNewClick(id: any) {
    history.push(location.pathname + `/new`)
  }

  async function archivePo(po: any) {
    const r = window.confirm(
      `Are you sure you want to delete: ${po.id || 'n/a'}`
    )

    if (r) {
      const { data: selectedPo } = await PurchaseOrder.find(po.id)
      selectedPo.isPersisted = true
      selectedPo.status = 'archived'
      await selectedPo.save()
      getPOs()
    }
  }

  const handleSearch = _.debounce(async (e: any) => {
    let query: any = {}
    if (search?.page) {
      query.page = search?.page
    }
    query.localSearchTerm = e.target?.value
    history.push(location.pathname + `?${QueryString.stringify(query)}`)
  }, 600)

  return (
    <Container>
      <Input
        onChange={handleSearch}
        defaultValue={search?.localSearchTerm}
        fluid
        icon="search"
        type="number"
        iconPosition="left"
        placeholder="Search by purchase order ID"
        loading={loading}
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Customer</Table.HeaderCell>
            <Table.HeaderCell>Salesman</Table.HeaderCell>
            <Table.HeaderCell>Vendor</Table.HeaderCell>
            <Table.HeaderCell>Created On</Table.HeaderCell>
            <Table.HeaderCell>
              <Button
                icon
                fluid
                positive
                labelPosition="left"
                onClick={handleNewClick}
              >
                <Icon name="file" />
                New PO
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {pos.map((po: any) => {
            return (
              <Table.Row key={po.id}>
                <Table.Cell>{po.id}</Table.Cell>
                <Table.Cell width="3">{po.customer?.name}</Table.Cell>
                <Table.Cell width="3">{po.user?.name}</Table.Cell>
                <Table.Cell width="3">{po.vendor?.name}</Table.Cell>
                <Table.Cell width="2">
                  {moment.utc(po.createdAt, 'YYYY-MM-DD hh:mm:ss').format('ll')}
                </Table.Cell>
                <Table.Cell>
                  <div>
                    <Button
                      icon
                      fluid
                      labelPosition="left"
                      className={styles.routesButton}
                      onClick={() => handleEditClick(po.id)}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>
                    <Button
                      icon
                      fluid
                      labelPosition="left"
                      className={styles.routesButton}
                      onClick={() => handleShowClick(po.id)}
                    >
                      <Icon name="book" />
                      View
                    </Button>
                    <Button
                      icon
                      fluid
                      negative
                      labelPosition="left"
                      onClick={() => archivePo(po)}
                    >
                      <Icon name="delete" />
                      Archive
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {totalPages > 0 ? (
        <Pagination
          size={isMobile ? 'mini' : ''}
          ellipsisItem={false}
          defaultActivePage={search.page || 1}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          siblingRange={isMobile ? -1 : 1}
        />
      ) : (
        <></>
      )}
    </Container>
  )
}
