import { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router'
import {
  Container,
  Dimmer,
  Loader,
  Table,
  Placeholder,
  Pagination,
  Button,
  Icon,
  Input,
  Dropdown,
  Grid,
} from 'semantic-ui-react'
import User from '../../models/user'
import ShowUser from './showUser'
import EditUser from './editUser'
import { useHistory, useLocation } from 'react-router-dom'
import QueryString from 'qs'
import NewUser from './newUser'
import _ from 'lodash'
import styles from '../pages.module.scss'
import useMobile from '../../shared/hooks/useMobile'

const userTypes = ['customer', 'employee', 'vendor', 'salesman', 'manufacturer']

export default function UserRoutes() {
  return (
    <Switch>
      <Route exact path="/users">
        <UserListView />
      </Route>
      <Route exact path="/users/new">
        <NewUser />
      </Route>
      <Route exact path="/users/:id/edit">
        <EditUser />
      </Route>
      <Route path="/users/:id">
        <ShowUser />
      </Route>
    </Switch>
  )
}

function UserListView() {
  const param: any = useParams()

  const history: any = useHistory()
  const location: any = useLocation()
  const isMobile = useMobile()
  let search: any = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const [loading, setLoading] = useState<any>(false)
  const [users, setUsers] = useState<any>([])
  const [totalPages, setTotalPages] = useState<any>(0)

  useEffect(() => {
    getUsers()
  }, [param])

  useEffect(() => {
    document.title = 'Users | PFP'
  }, [])

  async function getUsers() {
    setLoading(true)
    let scope: any = User

    let defaultFilter = search?.filter
    scope = scope
      .where({
        status: 'active',
        user_type: defaultFilter,
      })
      .page(search?.page)
      .per(10)
      .order({ name: 'asc' })

    if (search?.localSearchTerm) {
      scope = scope.where({ name: { fuzzyMatch: search?.localSearchTerm } })
    } else {
      scope = scope.stats({ total: 'count' })
    }
    let data = await scope.all()

    if (data.meta.stats) {
      setTotalPages(Math.ceil(data.meta.stats.total.count / 10))
    } else {
      setTotalPages(Math.ceil(data.data.length / 10))
    }

    setUsers(data.data)
    setLoading(false)
  }

  async function handlePageChange(e: any, data: any) {
    search.page = data.activePage
    history.push(location.pathname + `?${QueryString.stringify(search)}`)
  }

  if (loading) {
    return (
      <Container>
        <Dimmer active>
          <Loader />
        </Dimmer>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Container>
    )
  }

  function handleEditClick(id: any) {
    history.push(location.pathname + `/${id}/edit`)
  }

  function handleShowClick(id: any) {
    history.push(location.pathname + `/${id}`)
  }
  function handleNewClick(id: any) {
    history.push(location.pathname + `/new`)
  }

  async function handleArchiveUser(user: any) {
    const r = window.confirm(
      `Are you sure you want to delete: ${user.name || 'n/a'}`
    )

    if (r) {
      const { data: selectedUser } = await User.find(user.id)
      selectedUser.isPersisted = true
      selectedUser.status = 'archived'
      await selectedUser.save()
      getUsers()
    }
  }

  const handleSearch = _.debounce(async (e: any) => {
    let query: any = {}
    if (search?.page) query.page = search?.page
    if (search?.filter) query.filter = search?.filter

    query.localSearchTerm = e.target?.value
    history.push(location.pathname + `?${QueryString.stringify(query)}`)
  }, 600)

  const handleDropDown = _.debounce(async (e: any, data: any) => {
    let query: any = {}
    query.filter = data.value
    history.push(location.pathname + `?${QueryString.stringify(query)}`)
  }, 600)

  return (
    <Container>
      <Grid stackable columns={2}>
        <Grid.Column>
          <Input
            onChange={handleSearch}
            defaultValue={search?.localSearchTerm}
            fluid
            icon="search"
            iconPosition="left"
            loading={loading}
            placeholder="Search by user name, email, or phone number"
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            placeholder="User Type"
            search
            fluid
            selection
            value={search?.filter}
            options={userTypes.map((item: any) => {
              return { key: item, text: item, value: item }
            })}
            onChange={handleDropDown}
          />
        </Grid.Column>
      </Grid>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>
              <Button
                positive
                icon
                fluid
                labelPosition="left"
                onClick={handleNewClick}
              >
                <Icon name="plus" />
                New User
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users.map((user: any) => {
            return (
              <Table.Row key={user.id}>
                <Table.Cell>{user.id}</Table.Cell>
                <Table.Cell>{user.name}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.phone}</Table.Cell>
                <Table.Cell>{user.status}</Table.Cell>
                <Table.Cell>
                  <Button
                    icon
                    fluid
                    labelPosition="left"
                    className={styles.routesButton}
                    onClick={() => handleEditClick(user.id)}
                  >
                    <Icon name="edit" />
                    Edit
                  </Button>
                  <Button
                    icon
                    fluid
                    labelPosition="left"
                    className={styles.routesButton}
                    onClick={() => handleShowClick(user.id)}
                  >
                    <Icon name="book" />
                    View
                  </Button>
                  {user.status !== 'archived' && (
                    <Button
                      icon
                      negative
                      fluid
                      labelPosition="left"
                      onClick={() => handleArchiveUser(user)}
                    >
                      <Icon name="delete" />
                      Archive
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {totalPages > 0 ? (
        <Pagination
          size={isMobile ? 'mini' : ''}
          ellipsisItem={false}
          defaultActivePage={search.page || 1}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          siblingRange={isMobile ? -1 : 1}
        />
      ) : (
        <></>
      )}
    </Container>
  )
}
