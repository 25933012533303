import { useEffect, useRef, useState } from 'react'
import './App.css'
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Link,
  Redirect,
} from 'react-router-dom'
import { Header, Input, Label, List, Modal, Segment } from 'semantic-ui-react'
import QueryString from 'qs'
import _ from 'lodash'
import url from './utils/base_url'
import MainLayout from './layouts/mainLayout'
import Login from './pages/login/Login'

function App() {
  const [globalResults, setGlobalResults] = useState<any>([])
  const location = useLocation()
  const history = useHistory()
  const inputRef: any = useRef()
  let fullSearch: any = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  let globalSearch = Boolean(fullSearch?.searchOpen)

  useEffect(() => {
    window.addEventListener('keydown', handleSpecialKeys, false)
    return () => {
      window.removeEventListener('keydown', handleSpecialKeys)
    }
  }, [])

  useEffect(() => {
    inputRef?.current?.focus()
  }, [globalSearch])

  function handleSearchItemClick(item: any) {
    switch (item.type) {
      case 'Customer':
        history.push(`/customers/${item.record.id}`)
        break
      case 'Manufacturer':
        history.push(`/manufacturers/${item.record.id}`)
        break
      case 'Vendor':
        history.push(`/vendors/${item.record.id}`)
        break
      case 'User':
        history.push(`/users/${item.record.id}`)
        break
      default:
        break
    }
  }

  function generateHref(item: any) {
    return `/${item.type.toLowerCase()}s/${item.record.id}`
  }

  function handleSpecialKeys(e: any) {
    // if the keycode is the escape key and global search is open
    // close the global search
    if (e.keyCode === 27 && globalSearch) {
      history.push(location.pathname)
    }

    // if the keycode is k key and metaKey (cmd key on mac) is pressed
    // toggle the globalsearch visibility
    if ((e.keyCode === 75 && e.metaKey) || (e.ctrlKey && e.keyCode === 75)) {
      e.preventDefault()
      setGlobalResults([])
      history.push(location.pathname + `?searchOpen=true`)
    }
  }

  async function fetchSearchResults() {
    let term = fullSearch.searchTerm

    let results = await fetch(
      `${url}/search?search_term=${term}&limit=20`
    ).then(async (res: any) => await res.json())
    setGlobalResults(results)
  }

  const handleSearchChange = _.debounce((e) => {
    if (e.target.value !== '') {
      fullSearch.searchTerm = escape(e.target.value)
    } else {
      delete fullSearch.searchTerm
    }
    history.push(location.pathname + `?${QueryString.stringify(fullSearch)}`)
    fetchSearchResults()
  }, 600)

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="*">
            <MainLayout />
          </Route>
        </Switch>
      </div>
      <Modal
        onClose={() => history.push(location.pathname)}
        open={globalSearch}
      >
        <Modal.Content image>
          <Modal.Description>
            <Header>Search</Header>
            <Input
              fluid
              focus
              placeholder="Search Users, Customers, Manufacturers, and Vendor names or Job / Purchase Order IDs"
              onChange={handleSearchChange}
              ref={inputRef}
              tabIndex={1}
            />
            {fullSearch.searchTerm && globalResults.length === 0 && (
              <Segment placeholder>
                <Header icon>
                  No search results found for {unescape(fullSearch.searchTerm)}
                </Header>
              </Segment>
            )}
            {globalResults.length > 0 && (
              <List
                selection
                divided
                verticalAlign="middle"
                className="overflow-scroll"
              >
                {globalResults.map((result: any, index: number) => {
                  return (
                    <List.Item
                      key={result.record.id}
                      onClick={() => handleSearchItemClick(result)}
                    >
                      <Link to={generateHref(result)} tabIndex={index + 1}>
                        <Label color="blue" horizontal>
                          {result.type}
                        </Label>
                        {result.record.name}
                      </Link>
                    </List.Item>
                  )
                })}
              </List>
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default App
