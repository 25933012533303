import { Formik } from 'formik'
import { Form } from 'semantic-ui-react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import isEmpty from 'lodash'

import './Login.css'
import { useHistory } from 'react-router'
import Credential from '../../models/credential'
import ApplicationRecord from '../../models/applicationRecord'
import { tokenWithPrefix } from '../../shared/hooks/userHook'
import { useEffect, useState } from 'react'
import { ResponseError } from 'spraypaint/lib-esm/request'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter valid email')
    .required('Username or Email is required.'),
  password: Yup.string().min(2, 'Too Short!').required('Password is required!'),
})

export default function Login() {
  const [errors, setErrors] = useState<string>()
  const history = useHistory()

  useEffect(() => {
    document.title = 'Login | PFP'
  })

  const login = async (
    values: any,
    { setSubmitting }: { setSubmitting: (val: boolean) => void }
  ) => {
    setSubmitting(true)
    let creds = new Credential({
      password: values.password,
      email: values.email,
    })
    try {
      localStorage.setItem('token', '')
      ApplicationRecord.jwt = ''

      await creds.save()

      if (!isEmpty(creds.errors)) {
        const errors: string[] = []
        Object.keys(creds.errors).map((key) => {
          errors.push(creds.errors[key]?.message || '')
        })
        setErrors(errors.join(' '))
        setSubmitting(false)
        return
      }
      localStorage.setItem(tokenWithPrefix, creds.jsonWebToken)
      history.push('/users')

      ApplicationRecord.jwt = creds.jsonWebToken
    } catch (error: any) {
      error as ResponseError
      const response = await error.response.json()
      setErrors(
        response.errors[0]?.detail ||
          response.errors[0]?.message ||
          'There was a server error. Please try again later.'
      )
      setSubmitting(false)
    }
  }

  return (
    <section className="login-container">
      <section className="blue-column" />
      <section className="login-form">
        <h2>Precision Fluid Power</h2>
        <Formik
          initialValues={{
            email: '',
            password: '',
            remember_me: false,
          }}
          validationSchema={LoginSchema}
          onSubmit={login}
        >
          {(props) => {
            return (
              <Form onSubmit={props.handleSubmit}>
                <Form.Input
                  id="email"
                  name="email"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    (props.errors.email && props.touched.email) || undefined
                  }
                  validationMessage={
                    props.errors.email && props.touched.email
                      ? props.errors.email
                      : null
                  }
                  label={
                    <p style={{ margin: 0, fontSize: 12, color: '#343A40' }}>
                      Email
                    </p>
                  }
                  placeholder="jane@acme.com"
                />
                <Form.Input
                  id="password"
                  name="password"
                  type="password"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    (props.errors.password && props.touched.password) ||
                    undefined
                  }
                  validationMessage={
                    props.errors.password && props.touched.password
                      ? props.errors.password
                      : null
                  }
                  label={
                    <p style={{ margin: 0, fontSize: 12, color: '#343A40' }}>
                      Password
                    </p>
                  }
                  placeholder="******"
                />
                <Form.Button fluid type="submit">
                  Login
                </Form.Button>
              </Form>
            )
          }}
        </Formik>
        {errors && <div className="error-message">{errors}</div>}
      </section>
    </section>
  )
}

Login.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  touched: PropTypes.object,
}
