import { Formik } from 'formik'
import { Button, Form } from 'semantic-ui-react'
import * as yup from 'yup'
import Manufacturer from '../../models/manufacturer'

let schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email(),
  phone: yup.string().nullable(),
  address: yup.string().nullable(),
  state: yup.string().nullable(),
  city: yup.string().nullable(),
  county: yup.string().nullable(),
  country: yup.string().nullable(),
})

export default function ManufacturerModal(props: any) {
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        state: '',
        county: '',
        country: '',
      }}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(async () => {
          let man: any = new Manufacturer({ ...values })

          const success = await man.save()
          if (success) {
            setSubmitting(false)
            props.handleCloseModal(man)
          }
        }, 400)
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }: /* and other goodies */
      any) => (
        <Form onSubmit={handleSubmit}>
          <Form.Field
            name="name"
            label="Name"
            control="input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={errors.name && touched.name && errors.name}
          />
          <Form.Field
            type="email"
            name="email"
            label="Email"
            control="input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={errors.email && touched.email && errors.email}
          />
          <Form.Field
            type="tel"
            name="phone"
            label="Phone"
            control="input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.phone}
            error={errors.phone && touched.phone && errors.phone}
          />
          <Form.Field
            name="city"
            label="City"
            control="input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city}
            error={errors.city && touched.city && errors.city}
          />
          <Form.Field
            name="state"
            label="State"
            control="input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.state}
            error={errors.state && touched.state && errors.state}
          />
          <Form.Field
            name="county"
            label="County"
            control="input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.county}
            error={errors.county && touched.county && errors.county}
          />
          <Form.Field
            name="country"
            label="Country"
            control="input"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.country}
            error={errors.country && touched.country && errors.country}
          />
          <Button primary type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
}
