import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { Container, Button, Grid, Icon, Card } from 'semantic-ui-react'
import Manufacturer from '../../models/manufacturer'
import { useHistory } from 'react-router-dom'
import styles from '../pages.module.scss'

export default function ShowManufacturer() {
  const param: any = useParams()
  const history: any = useHistory()
  const [manu, setManu] = useState<any>(null)
  useEffect(() => {
    getManufacturer()
  }, [param])

  async function getManufacturer() {
    if (!param) return
    let { data }: any = await Manufacturer.find(param?.id)
    setManu(data)
  }

  function handleEditClick(id: any) {
    history.push(`/manufacturers/${id}/edit`)
  }

  async function handleDelete(vendor: any) {
    const r = window.confirm(`Are you sure you want to delete: ${vendor.name}`)
    if (r) {
      const { data: man } = await Manufacturer.find(vendor.id)
      man.isPersisted = true
      man.status = 'removed'
      await man.save()
      getManufacturer()
    }
  }
  if (!manu) return <Container></Container>

  return (
    <Container>
      <Card fluid color="blue" style={{ padding: '1rem' }}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Card.Header>{manu?.name}</Card.Header>
            </Grid.Column>
            <Grid.Column>
              <Button
                icon
                labelPosition="left"
                onClick={() => handleEditClick(manu.id)}
              >
                <Icon name="edit" />
                Edit
              </Button>
              {manu?.status !== 'removed' && (
                <Button
                  icon
                  negative
                  labelPosition="left"
                  onClick={() => handleDelete(manu)}
                >
                  <Icon name="delete" />
                  Delete
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <Card fluid style={{ padding: '1rem' }}>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>Manufacturer: {manu?.id}</h2>
                <h2>Name: {manu?.name}</h2>
                <h2>Email: {manu?.email}</h2>
                <h2>Phone: {manu?.phone}</h2>
                <h2>Status: {manu?.status}</h2>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>Address: {manu?.address}</h2>
                <h2>City: {manu?.city}</h2>
                <h2>State: {manu?.state}</h2>
                <h2>County: {manu?.county}</h2>
                <h2>Country: {manu?.country}</h2>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
    </Container>
  )
}
