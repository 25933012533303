import { Formik } from 'formik'
import { Button, Container, Form } from 'semantic-ui-react'
import * as yup from 'yup'
import User from '../../models/user'
import { useHistory } from 'react-router-dom'

let schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email(),
  phone: yup.string().nullable(),
  title: yup.string().nullable(),
  address: yup.string().nullable(),
  state: yup.string().nullable(),
  city: yup.string().nullable(),
  county: yup.string().nullable(),
  country: yup.string().nullable(),
  zipcode: yup.string().nullable(),
})

const userTextOptions = ['customer', 'vendor', 'manufacturer', 'salesman']

const userOptions = userTextOptions.map((item) => {
  return {
    key: item,
    value: item.toLowerCase(),
    text: item,
  }
})

export default function NewUser() {
  const history = useHistory()
  return (
    <Container>
      <h2>Create a new User</h2>

      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          title: '',
          address: '',
          city: '',
          state: '',
          county: '',
          country: '',
          zipcode: '',
          userType: '',
        }}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(async () => {
            let nUser: any = new User({ ...values })
            const success = await nUser.save()
            if (success) {
              history.push(`/users/${nUser.id}`)
              setSubmitting(false)
            }
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }: /* and other goodies */
        any) => (
          <Form onSubmit={handleSubmit}>
            <Form.Field
              required
              name="name"
              label="Name"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name && touched.name && errors.name}
            />
            <Form.Field
              type="email"
              name="email"
              label="Email"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email && errors.email}
            />
            <Form.Field
              type="tel"
              name="phone"
              label="Phone"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              error={errors.phone && touched.phone && errors.phone}
            />
            <Form.Field
              name="title"
              label="Title"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              error={errors.title && touched.title && errors.title}
            />
            <Form.Field
              name="address"
              label="Address"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              error={errors.address && touched.address && errors.address}
            />
            <Form.Field
              name="city"
              label="City"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.city}
              error={errors.city && touched.city && errors.city}
            />
            <Form.Field
              name="state"
              label="State"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state}
              error={errors.state && touched.state && errors.state}
            />

            <Form.Field
              name="county"
              label="County"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.county}
              error={errors.county && touched.county && errors.county}
            />
            <Form.Field
              name="country"
              label="Country"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              error={errors.country && touched.country && errors.country}
            />
            <Form.Field
              name="zipcode"
              label="Zip Code"
              control="input"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.zipcode}
              error={errors.zipcode && touched.zipcode && errors.zipcode}
            />
            <Form.Select
              label="User Type"
              name="userType"
              placeholder="Select User Type"
              options={userOptions}
              onChange={(e: any, data: any) =>
                setFieldValue('userType', data.value)
              }
            />
            <Button primary type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}
