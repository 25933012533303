import { Formik } from 'formik'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Button, Container, Form, Label, Search } from 'semantic-ui-react'
import * as yup from 'yup'
import Customer from '../../models/customer'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import Vendor from '../../models/vendor'
import User from '../../models/user'
import Job from '../../models/job'
import { ManufacturerSearch } from '../../components/ManufacturerSearch/manufacturerSearch'
import { VendorSearch } from '../../components/VendorSearch/vendorSearch'
import { jobTypeOptions } from 'shared/utils/jobTypeUtil'

let schema = yup.object().shape({
  jobType: yup.string().required(),
  customer: yup.object().nullable(),
  user: yup.object().nullable(),
  vendor: yup.object().nullable(),
  manufacturer: yup.object().nullable(),
})

function JobEdit(props: any) {
  const history = useHistory()
  const param: any = useParams()

  const [job, setJob] = useState<any>(null)
  useEffect(() => {
    initiatialize()
  }, [param])

  async function initiatialize() {
    if (!param) return
    let { data }: any = await Job.includes([
      'customer',
      'vendor',
      'user',
      'manufacturer',
    ]).find(param?.id)
    setJob(data)
  }

  if (!job) return <></>

  return (
    <Container>
      <h2>Edit Job</h2>
      <Formik
        initialValues={{
          jobType: job.jobType,
          customer: job.customer || {},
          vendor: job.vendor || {},
          manufacturer: job.manufacturer || {},
          user: job.user || {},
        }}
        validationSchema={schema}
        onSubmit={(values: any, { setSubmitting }) => {
          setTimeout(async () => {
            let newJob: any = await Job.includes([
              'customer',
              'vendor',
              'user',
              'manufacturer',
            ]).find(job.id)
            newJob.data.customer = new Customer({ id: values.customer?.id })
            newJob.data.customer.isPersisted = true

            if (values.vendor?.id) {
              newJob.data.vendor = new Vendor({ id: values.vendor?.id })
              newJob.data.vendor.isPersisted = true
            }
            if (values.manufacturer?.id) {
              newJob.data.manufacturer = new Vendor({
                id: values.manufacturer?.id,
              })
              newJob.data.manufacturer.isPersisted = true
            }
            if (values.user?.id) {
              newJob.data.user = new User({ id: values.user?.id })
              newJob.data.user.isPersisted = true
            }

            newJob.data.jobType = values.jobType

            const success = await newJob.data.save({
              with: ['vendor.id', 'user.id', 'customer.id', 'manufacturer.id'],
            })
            if (success) {
              history.push(`/jobs/${job.id}`)
              setSubmitting(false)
            }
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }: /* and other goodies */
        any) => (
          <Form onSubmit={handleSubmit}>
            <Form.Select
              label="Job Type"
              name="jobType"
              placeholder="Select Job Type"
              options={jobTypeOptions}
              value={values.jobType}
              onChange={(e: any, data: any) =>
                setFieldValue('jobType', data.value)
              }
              error={errors.jobType && touched.jobType && errors.jobType}
            />

            <Form.Field>
              <label>Customer: {values.customer?.name}</label>
              <CustomerSearch
                onChange={setFieldValue}
                value={values.customer?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Vendor: {values.vendor?.name}</label>
              <VendorSearch
                onChange={setFieldValue}
                value={values.vendor?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Manufacturer: {values.manufacturer?.name}</label>
              <ManufacturerSearch
                onChange={setFieldValue}
                value={values.manufacturer?.name}
              />
            </Form.Field>
            <Form.Field>
              <label>Salesman: {values.user?.name}</label>
              <UserSearch onChange={setFieldValue} value={values.user?.name} />
            </Form.Field>

            <Button primary type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

interface UserSearchProps {
  onChange: (field: string, valObj: { [key: string]: string }) => void
  value: string
}

function UserSearch(props: UserSearchProps) {
  const [loading] = useState<any>(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<any>('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    let { data: userData }: any = await User.where({
      user_type: 'employee',
      status: 'active',
      name: { fuzzyMatch: searchTerm.replace('&', '') },
    }).all()
    setResults(userData)
  }
  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    onChange('user', { name: data.value })
    setSearchTerm(data.value)
  })

  function handleSelectItem(e: any, data: any) {
    onChange('user', data.result)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a user name"
    />
  )
}

interface CustomerSearchProps {
  onChange: (field: string, valObj: { [key: string]: string }) => void
  value: string
}

function CustomerSearch(props: CustomerSearchProps) {
  const [loading] = useState(false)
  const [results, setResults] = useState<any>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const { onChange } = props

  useEffect(() => {
    getResults()
  }, [searchTerm])

  const resultRenderer = ({ name }: any) => <Label content={name} />

  async function getResults() {
    let { data: custData }: any = await Customer.includes('salesman')
      .where({
        name: { fuzzyMatch: searchTerm.replace('&', '') },
      })
      .all()
    setResults(custData)
  }

  const handleSearchChange = _.debounce(async (e: any, data: any) => {
    onChange('customer', { name: data.value })
    setSearchTerm(data.value)
  })

  function handleSelectItem(e: any, data: any) {
    onChange('customer', data.result)
    onChange('user', data.result.salesman)
    setSearchTerm('')
  }

  return (
    <Search
      loading={loading}
      onResultSelect={handleSelectItem}
      onSearchChange={handleSearchChange}
      resultRenderer={resultRenderer}
      results={results}
      value={props.value}
      placeholder="Enter a customer name"
    />
  )
}

export default JobEdit
