import { Menu } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import styles from './leftNavStyles.module.css'

function NavbarMenuIcon({ text, ...rest }: any) {
  return <Menu.Item className={styles.menuItem}>{text}</Menu.Item>
}

function NavbarMenuImg({ text, ...rest }: any) {
  return (
    <Menu.Item className={styles.menuImg}>
      <i className={`${text} large icon`} />
    </Menu.Item>
  )
}

export default function LeftNav(props: any) {
  return (
    <div className={styles.navbar}>
      <Menu vertical borderless fluid className={styles.navbarMenu}>
        <NavLink to="/dashboard" activeClassName="active">
          <NavbarMenuIcon text="Dashboard" />
          <NavbarMenuImg text="home" />
        </NavLink>
        <NavLink to="/jobs" activeClassName="active">
          <NavbarMenuIcon text="Jobs" />
          <NavbarMenuImg text="briefcase" />
        </NavLink>
        <NavLink to="/purchase-orders?page=1" activeClassName="active">
          <NavbarMenuIcon text="Purchase Orders" />
          <NavbarMenuImg text="clipboard outline" />
        </NavLink>

        <NavLink to="/customers?page=1" activeClassName="active">
          <NavbarMenuIcon text="Customers" />
          <NavbarMenuImg text="address card outline" />
        </NavLink>
        <NavLink to="/vendors?page=1" activeClassName="active">
          <NavbarMenuIcon text="Vendors" />
          <NavbarMenuImg text="building outline" />
        </NavLink>
        <NavLink to="/manufacturers?page=1" activeClassName="active">
          <NavbarMenuIcon text="Manufacturers" />
          <NavbarMenuImg text="cogs" />
        </NavLink>
        <NavLink to="/users?page=1" activeClassName="active">
          <NavbarMenuIcon text="Users" />
          <NavbarMenuImg text="user" />
        </NavLink>
        <NavLink to="/logout">
          <NavbarMenuIcon text="Logout" />
        </NavLink>
      </Menu>
    </div>
  )
}
