import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Container,
  Form,
  Grid,
  Icon,
  Modal,
  Tab,
  Table,
  TextArea,
} from 'semantic-ui-react'
import { useHistory, useLocation } from 'react-router-dom'
import Note from '../../models/note'
import PurchaseOrder from '../../models/purchase_order'
import moment from 'moment'
import { PDFDocument } from 'pdf-lib'
import styles from '../pages.module.scss'
import useMobile from '../../shared/hooks/useMobile'

import { jobToPdf } from '../../shared/utils/jobToPdf'

export default function PurchaseOrderShow() {
  const param: any = useParams()
  const history: any = useHistory()
  const location: any = useLocation()

  const [text, setTextForModal] = useState('')
  const [addNoteModal, setAddNoteModal] = useState(false)

  const [po, setPO] = useState<any>(null)
  useEffect(() => {
    getPO()
  }, [param])

  async function getPO() {
    if (!param) return
    let { data }: any = await PurchaseOrder.includes([
      'notes',
      'customer',
      'customer.salesman',
      'user',
      'vendor',
      'manufacturer',
      'jobs',
    ]).find(param?.id)
    setPO(data)
  }

  function handleOpenNoteModal() {
    setAddNoteModal(true)
  }

  function handleSetTextForModal(text: any) {
    setTextForModal(text)
  }

  function handleEditClick(id: any) {
    setTextForModal(id)
    history.push(location.pathname + `/edit`)
  }

  async function handleSubmitNewText() {
    let nPo: any = await PurchaseOrder.find(po?.id)
    let newNote = new Note()
    newNote.text = text
    nPo.data.notes.push(newNote)
    const success = await nPo.data.save({ with: 'notes' })
    if (success) {
      getPO()
      setTextForModal(text)
      setAddNoteModal(false)
    }
  }

  const panes = [
    {
      menuItem: 'Jobs',
      render: () => (
        <Tab.Pane attached={false}>
          <JobsTable purchaseOrder={po} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Notes',
      render: () => (
        <Tab.Pane attached={false}>
          <NotesTable purchaseOrder={po} openModal={handleOpenNoteModal} />
        </Tab.Pane>
      ),
    },
  ]
  if (!po) return <></>

  return (
    <Container>
      <Card fluid color="blue" style={{ padding: '1rem' }}>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Card.Header>
                Purchase Order: {po?.id} for {po?.customer?.name}
              </Card.Header>
            </Grid.Column>
            <Grid.Column>
              <Button
                icon
                labelPosition="left"
                floated="right"
                onClick={() => handleEditClick(po.id)}
              >
                <Icon name="edit" />
                Edit
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <Card fluid style={{ padding: '1rem' }}>
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>
                  Customer: <br /> {po.customer?.name}
                </h2>
                <h2>
                  Salesman:
                  <br /> {po.user?.name || po.customer?.salesman?.name}
                </h2>
                <h2>
                  Vendor:
                  {po.vendor &&
                    `${po.vendor?.name}${
                      po.vendor?.status === 'removed' ? ' (removed)' : ''
                    }`}
                </h2>
                <h2>
                  Manufacturer:{' '}
                  {po.manufacturer &&
                    `${po.manufacturer?.name}${
                      po.manufacturer?.status === 'removed' ? ' (removed)' : ''
                    }`}
                </h2>
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className={styles.mobileText}>
                <h2>
                  Shipping Method: <br /> {po.shippingMethod}
                </h2>
                <h2>
                  Shipping Speed: <br /> {po.shippingSpeed}
                </h2>
                <h2>
                  Created On: <br /> {moment(po.createdAt).format('ll')}
                </h2>
                <h2>
                  Updated On: <br /> {moment(po.updatedAt).format('ll')}
                </h2>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      {po && <Tab menu={{ secondary: true, pointing: true }} panes={panes} />}
      <Modal
        onClose={() => setAddNoteModal(false)}
        onOpen={() => setAddNoteModal(true)}
        open={addNoteModal}
      >
        <Modal.Header>Add a new note for PO: {po?.id}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form>
              <TextArea
                placeholder="Note"
                name="text"
                onChange={(e: any, data: any) =>
                  handleSetTextForModal(data.value)
                }
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setAddNoteModal(false)}>
            Nope
          </Button>
          <Button primary type="submit" onClick={handleSubmitNewText}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  )
}

function NotesTable(props: any) {
  const { purchaseOrder, openModal } = props
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            Notes
            <Button
              icon
              labelPosition="left"
              onClick={openModal}
              floated="right"
            >
              <Icon name="plus" />
              Add Note
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {purchaseOrder.notes?.length > 0 &&
          purchaseOrder.notes?.map((note: any) => {
            return (
              <Table.Row key={note.id}>
                <Table.Cell>{note.text}</Table.Cell>
              </Table.Row>
            )
          })}
      </Table.Body>
    </Table>
  )
}

function JobsTable(props: any) {
  const history: any = useHistory()
  const location: any = useLocation()
  const isMobile = useMobile()

  function handleNewJobClick() {
    history.push(location.pathname + `/job`)
  }

  async function handlePrintJobForm(job: any) {
    let url =
      'https://precision-po-public-pdfs.s3.amazonaws.com/NEW_JOB_SHEET_PDF_edited_2.pdf'
    let file = await fetch(url).then((res) => res.arrayBuffer())
    let pdf = await PDFDocument.load(file)
    let formToPrint: PDFDocument = jobToPdf(job, pdf)

    let pdfBytes = await formToPrint.save()
    let pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' })
    let pdfUrl = URL.createObjectURL(pdfBlob)
    window.open(pdfUrl)
  }

  const { purchaseOrder } = props
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.HeaderCell>Created Date</Table.HeaderCell>
          <Table.HeaderCell>
            <Button
              positive
              icon
              labelPosition="left"
              floated={isMobile ? 'left' : 'right'}
              onClick={() => handleNewJobClick()}
            >
              <Icon name="file alternate outline" />
              New Job
            </Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {purchaseOrder.jobs?.length > 0 &&
          purchaseOrder.jobs?.map((job: any) => {
            job.purchaseOrder = purchaseOrder
            job.customer = purchaseOrder.customer
            return (
              <Table.Row key={job.id}>
                <Table.Cell>{job.id}</Table.Cell>
                <Table.Cell>{job.createdAt}</Table.Cell>
                <Table.Cell>
                  <div>
                    {/* <Button icon labelPosition="left" onClick={() => { }}>
                      <Icon name="edit" />
                      View
                    </Button> */}
                    <Button
                      primary
                      icon
                      floated="right"
                      labelPosition="left"
                      onClick={() => handlePrintJobForm(job)}
                    >
                      <Icon name="file pdf outline" />
                      Generate PDF
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
      </Table.Body>
    </Table>
  )
}
