import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import url from '../../utils/base_url'

export const tokenWithPrefix =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? 'pfp-token-dev'
    : 'pfp-token'

function CurrentUser() {
  let history = useHistory()
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    if (!currentUser) getCurrentUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getCurrentUser() {
    let userToken = await localStorage.getItem(tokenWithPrefix)
    if (!userToken) {
      history.push('/')
      return
    }

    let currUser = await fetch(`${url}/api/v1/users/current`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }).then((res) => res.json())

    if (currUser.errors) {
      history.push('/login')
    } else {
      setCurrentUser(currUser.data)
    }
  }
  return currentUser
}

export default CurrentUser
