export const jobTypes: any = {
  unassigned: 'None',
  cylinder: 'Cylinder',
  pump: 'Pump',
  other: 'Other',
  servo_valve: 'Servo Valve',
}

export const jobTypeOptions = Object.keys(jobTypes).map((item: any) => {
  return {
    text: jobTypes[item],
    value: item,
    key: item,
  }
})
