import { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router'
import {
  Table,
  Container,
  Pagination,
  Button,
  Icon,
  Input,
} from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import QueryString from 'qs'
import _ from 'lodash'
import Job from '../../models/job'
import JobsShow from './jobsShow'
import JobNew from './jobNew'
import JobEdit from './jobEdit'
import moment from 'moment'
import styles from '../pages.module.scss'
import useMobile from '../../shared/hooks/useMobile'

export default function JobRoutes() {
  return (
    <Switch>
      <Route exact path="/jobs">
        <JobListView />
      </Route>
      <Route exact path="/jobs/new">
        <JobNew />
      </Route>
      <Route exact path="/jobs/:id/edit">
        <JobEdit />
      </Route>
      <Route path="/jobs/:id">
        <JobsShow />
      </Route>
    </Switch>
  )
}

function JobListView() {
  const param: any = useParams()
  const history: any = useHistory()
  const location: any = useLocation()
  const isMobile = useMobile()
  let search: any = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState<any>(0)
  const [jobs, setJobs] = useState<any>([])

  useEffect(() => {
    getJobs()
  }, [param])

  useEffect(() => {
    document.title = 'Jobs | PFP'
  }, [])

  async function getJobs() {
    setLoading(true)
    let scope: any = Job

    scope = scope
      .includes(['customer', 'user'])
      .where({ status: 'active' })
      .page(search?.page)
      .per(10)
      .stats({ total: 'count' })
      .order({ id: 'desc' })
    if (search?.localSearchTerm) {
      scope = scope.where({ id: { fuzzyMatch: search?.localSearchTerm } })
    }

    let data = await scope.all()
    if (data.meta.stats) {
      setTotalPages(Math.ceil(data.meta.stats.total.count / 10))
    } else {
      setTotalPages(Math.ceil(data.data.length / 10))
    }
    setJobs(data.data)
    setLoading(false)
  }

  async function handlePageChange(e: any, data: any) {
    search.page = data.activePage
    history.push(location.pathname + `?${QueryString.stringify(search)}`)
  }

  function handleEditClick(id: any) {
    history.push(location.pathname + `/${id}/edit`)
  }

  function handleShowClick(id: any) {
    history.push(location.pathname + `/${id}`)
  }

  function handleNewClick() {
    history.push(location.pathname + `/new`)
  }

  async function archiveJob(job: any) {
    const r = window.confirm(
      `Are you sure you want to delete: ${job.id || 'n/a'}`
    )

    if (r) {
      const { data: selectedJob } = await Job.find(job.id)
      selectedJob.isPersisted = true
      selectedJob.status = 'archived'
      await selectedJob.save()
      getJobs()
    }
  }

  const handleSearch = _.debounce(async (e: any) => {
    let query: any = {}
    if (search?.page) {
      query.page = search?.page
    }
    query.localSearchTerm = e.target?.value
    history.push(location.pathname + `?${QueryString.stringify(query)}`)
  }, 600)

  return (
    <Container>
      <Input
        onChange={handleSearch}
        defaultValue={search?.localSearchTerm}
        fluid
        icon="search"
        type="number"
        iconPosition="left"
        placeholder="Search by job ID"
        loading={loading}
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Customer</Table.HeaderCell>
            <Table.HeaderCell>Salesman</Table.HeaderCell>
            <Table.HeaderCell>Created On</Table.HeaderCell>
            <Table.HeaderCell>
              <Button
                icon
                fluid
                positive
                labelPosition="left"
                onClick={() => handleNewClick()}
              >
                <Icon name="file" />
                New Job
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {jobs.map((job: any) => {
            return (
              <Table.Row key={job.id}>
                <Table.Cell width="1">{job.id}</Table.Cell>
                <Table.Cell width="3">{job.customer?.name}</Table.Cell>
                <Table.Cell width="3">{job.user?.name}</Table.Cell>
                <Table.Cell width="3">
                  {moment
                    .utc(job.createdAt, 'YYYY-MM-DD hh:mm:ss')
                    .format('ll')}
                </Table.Cell>
                <Table.Cell width="4">
                  <div>
                    <Button
                      icon
                      fluid
                      labelPosition="left"
                      className={styles.routesButton}
                      onClick={() => handleEditClick(job.id)}
                    >
                      <Icon name="edit" />
                      Edit
                    </Button>
                    <Button
                      icon
                      fluid
                      className={styles.routesButton}
                      labelPosition="left"
                      onClick={() => handleShowClick(job.id)}
                    >
                      <Icon name="book" />
                      View
                    </Button>
                    <Button
                      icon
                      fluid
                      negative
                      labelPosition="left"
                      onClick={() => archiveJob(job)}
                    >
                      <Icon name="delete" />
                      Archive
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {totalPages > 0 ? (
        <Pagination
          size={isMobile ? 'mini' : ''}
          ellipsisItem={false}
          defaultActivePage={search.page || 1}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          siblingRange={isMobile ? -1 : 1}
        />
      ) : (
        <></>
      )}
    </Container>
  )
}
