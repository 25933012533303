import { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router'
import {
  Container,
  Table,
  Pagination,
  Button,
  Icon,
  Input,
} from 'semantic-ui-react'

import Manufacturer from '../../models/manufacturer'
import { useHistory, useLocation } from 'react-router-dom'
import QueryString from 'qs'
import ShowManufacturer from './showManufacturer'
import NewManufacturer from './newManufacturer'
import EditManufacturer from './editManufacturer'
import _ from 'lodash'
import styles from '../pages.module.scss'
import useMobile from '../../shared/hooks/useMobile'

export default function ManufacturerRoutes() {
  return (
    <Switch>
      <Route exact path="/manufacturers">
        <ManufacturerListView />
      </Route>
      <Route exact path="/manufacturers/new">
        <NewManufacturer />
      </Route>
      <Route path="/manufacturers/:id/edit">
        <EditManufacturer />
      </Route>
      <Route path="/manufacturers/:id">
        <ShowManufacturer />
      </Route>
    </Switch>
  )
}

function ManufacturerListView() {
  const param: any = useParams()

  const history: any = useHistory()
  const location: any = useLocation()
  const isMobile = useMobile()
  let search: any = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const [loading, setLoading] = useState<any>(false)
  const [manufacturers, setManufacturers] = useState<any>([])
  const [totalPages, setTotalPages] = useState<any>(0)

  useEffect(() => {
    getManufacturers()
  }, [param])

  useEffect(() => {
    document.title = 'Manufacturers | PFP'
  }, [])

  async function getManufacturers() {
    setLoading(true)

    let scope: any = Manufacturer
    scope = scope
      .where({ status: 'active' })
      .page(search?.page)
      .per(10)
      .order({ name: 'asc' })
    if (search?.localSearchTerm) {
      scope = scope.where({ name: { fuzzyMatch: search?.localSearchTerm } })
    } else {
      scope = scope.stats({ total: 'count' })
    }
    let data = await scope.all()
    if (data.meta.stats) {
      setTotalPages(Math.ceil(data.meta.stats.total.count / 10))
    } else {
      setTotalPages(Math.ceil(data.data.length / 10))
    }

    setManufacturers(data.data)
    setLoading(false)
  }

  async function handlePageChange(e: any, data: any) {
    search.page = data.activePage
    history.push(location.pathname + `?${QueryString.stringify(search)}`)
  }

  function handleEditClick(id: any) {
    history.push(location.pathname + `/${id}/edit`)
  }

  function handleShowClick(id: any) {
    history.push(location.pathname + `/${id}`)
  }
  function handleNewClick(id: any) {
    history.push(location.pathname + `/new`)
  }

  async function handleDelete(vendor: any) {
    const r = window.confirm(`Are you sure you want to delete: ${vendor.name}`)
    if (r) {
      const { data: man } = await Manufacturer.find(vendor.id)
      man.isPersisted = true
      man.status = 'removed'
      await man.save()
      getManufacturers()
    }
  }

  const handleSearch = _.debounce(async (e: any) => {
    let query: any = {}
    if (search?.page) {
      query.page = search?.page
    }
    query.localSearchTerm = e.target?.value
    history.push(location.pathname + `?${QueryString.stringify(query)}`)
  }, 600)

  return (
    <Container>
      <Input
        onChange={handleSearch}
        defaultValue={search?.localSearchTerm}
        fluid
        icon="search"
        iconPosition="left"
        loading={loading}
        placeholder="Search by manufacturer name, email, or phone number"
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>
              <Button
                positive
                fluid
                icon
                labelPosition="left"
                onClick={handleNewClick}
              >
                <Icon name="plus" />
                New Manufacturer
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {manufacturers.map((customer: any) => {
            return (
              <Table.Row key={customer.id}>
                <Table.Cell>{customer.id}</Table.Cell>
                <Table.Cell>{customer.name}</Table.Cell>
                <Table.Cell>{customer.address}</Table.Cell>
                <Table.Cell>{customer.phone}</Table.Cell>
                <Table.Cell>{customer.status}</Table.Cell>
                <Table.Cell>
                  <Button
                    icon
                    fluid
                    labelPosition="left"
                    className={styles.routesButton}
                    onClick={() => handleEditClick(customer.id)}
                  >
                    <Icon name="edit" />
                    Edit
                  </Button>
                  <Button
                    icon
                    fluid
                    labelPosition="left"
                    className={styles.routesButton}
                    onClick={() => handleShowClick(customer.id)}
                  >
                    <Icon name="book" />
                    View
                  </Button>
                  {customer.status !== 'removed' && (
                    <Button
                      icon
                      fluid
                      negative
                      labelPosition="left"
                      onClick={() => handleDelete(customer)}
                    >
                      <Icon name="delete" />
                      Delete
                    </Button>
                  )}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
      {totalPages > 0 ? (
        <Pagination
          size={isMobile ? 'mini' : ''}
          ellipsisItem={false}
          defaultActivePage={search.page || 1}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          siblingRange={isMobile ? -1 : 1}
        />
      ) : (
        <></>
      )}
    </Container>
  )
}
