import { Attr, HasMany, HasOne, Model } from 'spraypaint'
import ApplicationRecord from './applicationRecord'
import Customer from './customer'
import Vendor from './vendor'
import Note from './note'
import User from './user'
import Manufacturer from './manufacturer'
import Job from './job'

@Model()
class PurchaseOrder extends ApplicationRecord {
  static jsonapiType = 'purchase_orders'

  @Attr() shippingMethod: string
  @Attr() shippingSpeed: string
  @Attr() status: string
  @HasOne() customer: Customer
  @HasOne() manufacturer: Manufacturer
  @HasOne() user: User
  @HasOne() vendor: Vendor
  @HasMany() notes: Note[]
  @HasMany() jobs: Job[]

  @Attr({ persist: false }) createdAt: string
  @Attr({ persist: false }) updatedAt: string

  get specialId(): any {
    return this.id
  }
}

export default PurchaseOrder
